@import "../Configration/Mixin.scss";
@import "../Configration/Veriabales.scss";

.button {
    display: inline-block;
    padding: .75rem 1.25rem;
    border: none;
    color: #fff;
    text-transform: uppercase;
    font-size:  responsive-font-size(1.3);
    padding: $spacing-unit 2.5rem;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $pry-color;
      
      z-index: -2;
    }
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: darken($pry-color, 15%);
      transition: all .3s;
      
      z-index: -1;
    }
    &:hover {
      color: #fff;
      &:before {
        width: 100%;
      }
    }
  }