@import "../Configration/Mixin.scss";
@import "../Configration/Veriabales.scss";
.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
  transition: background-color 0.3s ease;
  animation: fead 0.8s  ease-in-out;
}
@keyframes fead {
  0%{
    top: -50%;

  }
  100%{
    top: 0;
  }
}

header {
  transition: all 0.8s ease-in-out;
  background-color: #fff;
  ul {
    list-style: none;
    padding: 0;
    @include flex-center(row, flex-start);
  }

  li {
    margin-right: $spacing-unit;
    @include responsive(margin-right, $spacing-unit * 0.5);
  }
}

.top_wrp {
  @include flex-center(row, space-between, center, 992px);
  padding: 0.5rem 0;

  .logo {
    width: 40%;
    @include flex-center(row, flex-start, center, );

    img {
      @include responsive-image(80%, 70%, 100%);
      margin-right: $spacing-unit;
    }

  }
}

.nav_info {
  @include flex-center(row, flex-end);
  column-gap: 3rem;
  width: 100%;

  .contact {
    @include flex-center(row, space-between);
    column-gap: 1rem;

  }

  .info {
    margin-left: $spacing-unit;
    @include flex-center;
    cursor: pointer;

    &:hover i {
      background-color: $sec-color;
      color: $white-color;
      transform: scale(1.1);
    }

    h3 {
      font-size: responsive-font-size(1.1);
      color: $pry-color;
      font-weight: 500;
    }

    i {
      font-size: responsive-font-size(1.5);
      margin-right: $spacing-unit;
      color: $pry-color;
      padding: 0.8rem 1rem;
      background-color: #0097ee3a;
      transition: all 0.4s ease-in-out;
      border-radius: 5px;
    }

    p {
      font-size: responsive-font-size(1);
      opacity: 0.8;
      margin-bottom: 0.3rem;
    }

  }

  .sosialmidia {

    // margin: 0 $spacing-unit;
    a {
      i {
        font-size: responsive-font-size(1.5);
        color: $pry-color;
        padding: 0.8rem 1rem;
        background-color: #0097ee3a;
        transition: all 0.4s ease-in-out;
        border-radius: 5px;

        &:hover {
          background-color: $sec-color;
          color: $white-color;
          transform: scale(1.1);
        }

      }
    }
  }
}

.main_menu {
  background-color: $sec-color;
  padding: 1rem 0;

  .menu_wrp {
    @include flex-center(row, space-between, center); // Flexbox layout
    width: 100%;

    ul {
      li {
        padding-right: 1rem;
        
        
        a {
          position: relative;
          color: $white-color;
          font-size: responsive-font-size(1.2);
          letter-spacing: 0.50px;

          &.active {
            color: $pry-color !important;

            &::after{
              position: absolute;
              // top: 0;
              border-radius: 5px;
              bottom: -8px;
              left: 0;
              right: 0;
              width: auto;
              height: 3px;
              background-color: $pry-color;
              content: "";
              animation: slide 0.4s ease-in-out;
            }
            @keyframes slide {
              to{
                right: 0;
              }
              from{
                right: 100%;
              }
              
            
            }
          }
        }



      }
    }
  }
}

.mobile_nav {
  display: none;
}

.toggle_menu {
  display: none;
}

@media screen and (max-width:993px) {
  header {
    display: none;
  }

  .mobile_nav.colorChange {
    position: fixed;
    width: 100%;
    animation: animed 0.6s ease-in-out;
    top: 0;
    background-color: $white-color;
    z-index: 6;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.39);
    border-bottom: 0.5px solid #00000055;
  }

  @keyframes animed {
    0% {
      transform: translateY(-100%);
    }

    100% {
      transform: translateY(0%);
    }
  }

  .mobile_nav {
    display: block;
    position: relative;
    width: 100%;
    top: 0;
    background-color: $white-color;
    z-index: 1111;

    .top_nav {
      background-color: $pry_color;
      padding: 0.3rem 0;

      .top_wrp {
        width: 100%;
        @include flex-center(row, space-between, center);
        padding: 0.5rem 0;
      }

      .nav_info {
        @include flex-center(row, space-between, flex-start);
        column-gap: 3rem;
        width: 100%;


        .contact {
          @include flex-center(row, space-between, flex-start);
          column-gap: 1rem;
          gap: 1rem;
          width: 100%;
        }

        .info {
          margin-left: $spacing-unit;
          @include flex-center;
          cursor: pointer;

          &:hover i {
            background-color: $sec-color;
            color: $white-color;
            transform: scale(1.1);
          }

          h3 {
            font-size: responsive-font-size(1);
            color: $white-color;
            font-weight: 500;
          }

          i {
            font-size: responsive-font-size(1.2);
            margin-right: $spacing-unit;
            color: $pry-color;
            padding: 0.4rem 0.6rem;
            background-color: #b4d4e7d6;
            transition: all 0.4s ease-in-out;
            border-radius: 5px;
          }

          p {
            font-size: responsive-font-size(1);
            opacity: 0.8;
            margin-bottom: 0.3rem;
            color: #fff;
          }

        }

        .sosialmidia {

          // margin: 0 $spacing-unit;
          a {
            i {
              font-size: 12px;
              color: $pry-color;
              padding: 0.4rem 0.6rem;
              background-color: #0097ee3a;
              transition: all 0.4s ease-in-out;
              border-radius: 5px;

              &:hover {
                background-color: $sec-color;
                color: $white-color;
                transform: scale(1.1);
              }

            }
          }
        }
      }
    }

    .nav_bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 0;

      a {
        .logo__ {
          width: 40%;

          img {
            width: 100%;
          }
        }
      }

      .namaste {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 100%;

        h3 {
          font-size: 1em;
          font-weight: 600;
          color: #000;
          margin: 0;
        }

        img {
          width: 20%;
        }
      }

      .toggle_button {
        button {
          position: relative;
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          border: none;
          background-color: transparent;
          display: block;
          z-index: 4;

          &.menuicons span {
            transform: translateX(60px);
          }

          &.menuicons span:nth-child(1) {
            transform: translateY(0px);
            width: 20px;
            transform: rotate(50deg);
          }

          &.menuicons span:nth-child(2) {
            transform: translateY(0px);
            width: 20px;
            transform: rotate(128deg);
          }

          & span {
            position: absolute;
            width: 30px;
            height: 2.5px;
            background-color: #000;
            border-radius: 4px;
            transition: 0.5s;
            right: 15px;

            &:nth-child(1) {
              transform: translateY(-8px);
              width: 20px;
              left: 15px;
            }

            &:nth-child(2) {
              transform: translateY(8px);
              width: 20px;
              left: 15px;
            }
          }
        }

        .menu-one {
          display: none;
        }
      }
    }
  }

  .toggle_menu {
    display: block;
    position: fixed;
    top: 30px;
    right: 0;
    left: 0;
    bottom: 0;
    content: "";
    z-index: 5;
    box-shadow: 1px 10px 11px 3px #00000017;
    font-family: "Kanit", sans-serif;
    background-color: $white-color;
    margin: 0;
    transform: translate(110%);
    padding-top: 6rem;
    overflow: hidden;
    overflow-y: scroll;
    height: auto;

    .menus {
      ul {
        padding: 0;

        .dropdown {
          padding: 0;
        }

        li {
          display: block;
          text-align: left;
          margin: 0rem 2rem;
          padding: 0.5rem;
          border-bottom: 1px dotted rgb(199, 198, 198);

          span {
            font-size: 0.8em;
            padding-bottom: 1rem;
            border-bottom: 1px dotted rgb(199, 198, 198);
            width: 100%;
            display: block;
          }

          button {
            border: none;
            background-color: transparent;
            color: #000;
            font-weight: 600;
            font-size: 0.8em;
          }

          .dropdown-toggle {
            position: relative;

            &::after {
              display: inline-block;
              margin-left: 0;
              vertical-align: 0.255em;
              content: "";
              border-top: 0.3em solid;
              border-right: 0.3em solid transparent;
              border-bottom: 0;
              border-left: 0.3em solid transparent;
              position: absolute;
              top: 50%;
              right: 0;
            }
          }

          a {
            font-size: 0.8em;
            font-weight: 400;
            letter-spacing: 0.50px;
            // font-family: "Kanit", sans-serif;  
            line-height: 2rem;
            text-decoration: none;
            color: #000;
            text-transform: capitalize;

            &.active {
              color: #000;
            }
          }

          ul {
            background-color: transparent;
            border: none;
            margin-top: 0.5rem;

            li {
              padding: 0;
              background-color: #fff;
              margin: 0rem;
              padding: 0 1rem;
            }
          }
        }
      }
    }

    .bottom {
      padding-top: 3rem;
    }

    .add {
      position: relative;
      top: 0;
      bottom: 0;
      background-color: transparet;
      height: auto;
      color: #000;
      padding: 2rem 2rem 0;
      text-align: center;

      i {
        font-size: 0.9rem;

        padding-bottom: 1rem;
      }

      h1 {
        font-size: 1rem;
        font-weight: 600;
        padding-bottom: 0.5rem;
        font-family: "Kanit", sans-serif;
      }

      h6 {
        padding-bottom: 2rem;
        border-bottom: 1px solid #f3f0f040;
        font-size: 0.8rem;
        opacity: 0.5;
        font-family: "Kanit", sans-serif;
      }

      p {
        font-size: 0.8rem;
        opacity: 0.5;
        padding-bottom: 1rem;
      }

      ul {
        li {
          border: none;
          display: inline;
          padding: 0;
          margin: 0 0.5rem;
          background-color: transparent;

          a {
            color: #000;
            font-size: 1.2rem;
            opacity: 0.5;
          }
        }
      }
    }
  }

  .actives {
    transform: translate(0%);
    transition: 0.8s;
  }
}


@media screen and (max-width:500px) {
  header {
    display: none;
  }

  .mobile_nav.colorChange {
    position: fixed;
    width: 100%;
    animation: animed 0.6s ease-in-out;
    top: 0;
    background-color: $white-color;
    z-index: 6;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.39);
    border-bottom: 0.5px solid #00000055;
  }

  @keyframes animed {
    0% {
      transform: translateY(-100%);
    }

    100% {
      transform: translateY(0%);
    }
  }

  .mobile_nav {
    display: block;
    position: relative;
    width: 100%;
    top: 0;
    z-index: 1111;

    .top_nav {
      background-color: $pry_color;
      padding: 0.3rem 0;

      .top_wrp {
        @include flex-center(row, space-between, center, 992px);
        padding: 0.5rem 0;

      }

      .nav_info {
        @include flex-center(row, space-between, flex-start);
        column-gap: 3rem;
        width: 100%;




        .info {
          margin-left: 0;
          @include flex-center;
          cursor: pointer;

          &:hover i {
            background-color: $sec-color;
            color: $white-color;
            transform: scale(1.1);
          }

          h3 {
            font-size: 10px;
            color: $white-color;
            font-weight: 500;
            margin: 0;
          }

          i {
            font-size: 12px;
            margin-right: 5px;
            color: $pry-color;
            padding: 0.4rem 0.4rem;
            background-color: #b4d4e7d6;
            transition: all 0.4s ease-in-out;
            border-radius: 5px;
          }

          p {
            font-size: 8px;
            opacity: 0.8;
            margin-bottom: 0.3rem;
            color: #fff;
          }

        }

        .sosialmidia {

          // margin: 0 $spacing-unit;
          a {
            i {
              font-size: 12px;
              color: $pry-color;
              padding: 0.4rem 0.6rem;
              background-color: #0097ee3a;
              transition: all 0.4s ease-in-out;
              border-radius: 5px;

              &:hover {
                background-color: $sec-color;
                color: $white-color;
                transform: scale(1.1);
              }

            }
          }
        }
      }
    }

    .nav_bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 0;

      a {
        .logo__ {
          width: 40%;

          img {
            width: 100%;
          }
        }
      }

      .namaste {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 100%;

        h3 {
          font-size: 1em;
          font-weight: 600;
          color: #000;
          margin: 0;
        }

        img {
          width: 20%;
        }
      }

      .toggle_button {
        button {
          position: relative;
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          border: none;
          background-color: transparent;
          display: block;
          z-index: 4;

          &.menuicons span {
            transform: translateX(60px);
          }

          &.menuicons span:nth-child(1) {
            transform: translateY(0px);
            width: 20px;
            transform: rotate(50deg);
          }

          &.menuicons span:nth-child(2) {
            transform: translateY(0px);
            width: 20px;
            transform: rotate(128deg);
          }

          & span {
            position: absolute;
            width: 30px;
            height: 2.5px;
            background-color: #000;
            border-radius: 4px;
            transition: 0.5s;
            right: 15px;

            &:nth-child(1) {
              transform: translateY(-8px);
              width: 20px;
              left: 15px;
            }

            &:nth-child(2) {
              transform: translateY(8px);
              width: 20px;
              left: 15px;
            }
          }
        }

        .menu-one {
          display: none;
        }
      }
    }
  }

  .toggle_menu {
    display: block;
    position: fixed;
    top: 30px;
    right: 0;
    left: 0;
    bottom: 0;
    content: "";
    z-index: 5;
    box-shadow: 1px 10px 11px 3px #00000017;
    font-family: "Kanit", sans-serif;
    background-color: $white-color;
    margin: 0;
    transform: translate(110%);
    padding-top: 6rem;
    overflow: hidden;
    overflow-y: scroll;
    height: auto;

    .menus {
      ul {
        padding: 0;

        .dropdown {
          padding: 0;
        }

        li {
          display: block;
          text-align: left;
          margin: 0rem 2rem;
          padding: 0.5rem;
          border-bottom: 1px dotted rgb(199, 198, 198);

          button {
            border: none;
            background-color: transparent;
            color: #000;
            font-weight: 600;
            font-size: 0.8em;
          }

          .dropdown-toggle {
            position: relative;

            &::after {
              display: inline-block;
              margin-left: 0;
              vertical-align: 0.255em;
              content: "";
              border-top: 0.3em solid;
              border-right: 0.3em solid transparent;
              border-bottom: 0;
              border-left: 0.3em solid transparent;
              position: absolute;
              top: 50%;
              right: 0;
            }
          }

          a {
            font-size: 0.8em;
            font-weight: 400;
            letter-spacing: 0.50px;
            // font-family: "Kanit", sans-serif;  
            line-height: 2rem;
            text-decoration: none;
            color: #000;
            text-transform: capitalize;

            &.active {
              color: #000;
            }
          }

          ul {
            background-color: transparent;
            border: none;

            li {
              padding: 0;
              background-color: #fff;
              margin: 0rem;
              padding: 0 1rem;
            }
          }
        }
      }
    }

    .bottom {
      padding-top: 3rem;
    }

    .add {
      position: relative;
      top: 0;
      bottom: 0;
      background-color: transparet;
      height: auto;
      color: #000;
      padding: 2rem 2rem 0;
      text-align: center;

      i {
        font-size: 0.9rem;

        padding-bottom: 1rem;
      }

      h1 {
        font-size: 1rem;
        font-weight: 600;
        padding-bottom: 0.5rem;
        font-family: "Kanit", sans-serif;
      }

      h6 {
        padding-bottom: 2rem;
        border-bottom: 1px solid #f3f0f040;
        font-size: 0.8rem;
        opacity: 0.5;
        font-family: "Kanit", sans-serif;
      }

      p {
        font-size: 0.8rem;
        opacity: 0.5;
        padding-bottom: 1rem;
      }

      ul {
        li {
          border: none;
          display: inline;
          padding: 0;
          margin: 0 0.5rem;
          background-color: transparent;

          a {
            color: #000;
            font-size: 1.2rem;
            opacity: 0.5;
          }
        }
      }
    }
  }

  .actives {
    transform: translate(0%);
    transition: 0.8s;
  }
}


@media screen and (max-width:375px) {
  header {
    display: none;
  }

  .fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999;
    transition: background-color 0.3s ease;
    animation: fead 0.8s  ease-in-out;
  }

  .mobile_nav.colorChange {
    position: fixed;
    width: 100%;
    animation: animed 0.6s ease-in-out;
    top: 0;
    background-color: $white-color;
    z-index: 6;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.39);
    border-bottom: 0.5px solid #00000055;
  }

  @keyframes animed {
    0% {
      transform: translateY(-100%);
    }

    100% {
      transform: translateY(0%);
    }
  }

  .mobile_nav {
    display: block;
    position: relative;
    width: 100%;
    top: 0;
    z-index: 1111;

    .top_nav {
      background-color: $pry_color;
      padding: 0.3rem 0;

      .top_wrp {
        @include flex-center(row, space-between, center, 992px);
        padding: 0.5rem 0;

        a {
          .logo__ {
            width: 40%;
  
            img {
              width: 100%;
            }
          }
        }

      }

      .nav_info {
        @include flex-center(row, space-between, flex-start);
        column-gap: 3rem;
        width: 100%;


        .info {
          margin-left: 0;
          @include flex-center;
          cursor: pointer;

          &:hover i {
            background-color: $sec-color;
            color: $white-color;
            transform: scale(1.1);
          }

          h3 {
            font-size: 8px;
            color: $white-color;
            font-weight: 500;
            margin: 0;
          }

          i {
            font-size: 12px;
            margin-right: 5px;
            color: $pry-color;
            padding: 0.4rem 0.4rem;
            background-color: #b4d4e7d6;
            transition: all 0.4s ease-in-out;
            border-radius: 5px;
          }

          p {
            font-size: 8px;
            opacity: 0.8;
            margin-bottom: 0.3rem;
            color: #fff;
          }

        }

        .sosialmidia {

          // margin: 0 $spacing-unit;
          a {
            i {
              font-size: 12px;
              color: $pry-color;
              padding: 0.4rem 0.6rem;
              background-color: #0097ee3a;
              transition: all 0.4s ease-in-out;
              border-radius: 5px;

              &:hover {
                background-color: $sec-color;
                color: $white-color;
                transform: scale(1.1);
              }

            }
          }
        }
      }
    }

    .nav_bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 0;

      a {
        .logo__ {
          width: 40%;

          img {
            width: 100%;
          }
        }
      }

      .namaste {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 100%;

        h3 {
          font-size: 1em;
          font-weight: 600;
          color: #000;
          margin: 0;
        }

        img {
          width: 20%;
        }
      }

      .toggle_button {
        button {
          position: relative;
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          border: none;
          background-color: transparent;
          display: block;
          z-index: 4;

          &.menuicons span {
            transform: translateX(60px);
          }

          &.menuicons span:nth-child(1) {
            transform: translateY(0px);
            width: 20px;
            transform: rotate(50deg);
          }

          &.menuicons span:nth-child(2) {
            transform: translateY(0px);
            width: 20px;
            transform: rotate(128deg);
          }

          & span {
            position: absolute;
            width: 30px;
            height: 2.5px;
            background-color: #000;
            border-radius: 4px;
            transition: 0.5s;
            right: 15px;

            &:nth-child(1) {
              transform: translateY(-8px);
              width: 20px;
              left: 15px;
            }

            &:nth-child(2) {
              transform: translateY(8px);
              width: 20px;
              left: 15px;
            }
          }
        }

        .menu-one {
          display: none;
        }
      }
    }
  }

  .toggle_menu {
    display: block;
    position: fixed;
    top: 30px;
    right: 0;
    left: 0;
    bottom: 0;
    content: "";
    z-index: 5;
    box-shadow: 1px 10px 11px 3px #00000017;
    font-family: "Kanit", sans-serif;
    background-color: $white-color;
    margin: 0;
    transform: translate(110%);
    padding-top: 6rem;
    overflow: hidden;
    overflow-y: scroll;
    height: auto;

    .menus {
      ul {
        padding: 0;

        .dropdown {
          padding: 0;
        }

        li {
          display: block;
          text-align: left;
          margin: 0rem 2rem;
          padding: 0.5rem;
          border-bottom: 1px dotted rgb(199, 198, 198);

          button {
            border: none;
            background-color: transparent;
            color: #000;
            font-weight: 600;
            font-size: 0.8em;
          }

          .dropdown-toggle {
            position: relative;

            &::after {
              display: inline-block;
              margin-left: 0;
              vertical-align: 0.255em;
              content: "";
              border-top: 0.3em solid;
              border-right: 0.3em solid transparent;
              border-bottom: 0;
              border-left: 0.3em solid transparent;
              position: absolute;
              top: 50%;
              right: 0;
            }
          }

          a {
            font-size: 0.8em;
            font-weight: 400;
            letter-spacing: 0.50px;
            // font-family: "Kanit", sans-serif;  
            line-height: 2rem;
            text-decoration: none;
            color: #000;
            text-transform: capitalize;

            &.active {
              color: #000;
            }
          }

          ul {
            background-color: transparent;
            border: none;

            li {
              padding: 0;
              background-color: #fff;
              margin: 0rem;
              padding: 0 1rem;
            }
          }
        }
      }
    }

    .bottom {
      padding-top: 3rem;
    }

    .add {
      position: relative;
      top: 0;
      bottom: 0;
      background-color: transparet;
      height: auto;
      color: #000;
      padding: 2rem 2rem 0;
      text-align: center;

      i {
        font-size: 0.9rem;

        padding-bottom: 1rem;
      }

      h1 {
        font-size: 1rem;
        font-weight: 600;
        padding-bottom: 0.5rem;
        font-family: "Kanit", sans-serif;
      }

      h6 {
        padding-bottom: 2rem;
        border-bottom: 1px solid #f3f0f040;
        font-size: 0.8rem;
        opacity: 0.5;
        font-family: "Kanit", sans-serif;
      }

      p {
        font-size: 0.8rem;
        opacity: 0.5;
        padding-bottom: 1rem;
      }

      ul {
        li {
          border: none;
          display: inline;
          padding: 0;
          margin: 0 0.5rem;
          background-color: transparent;

          a {
            color: #000;
            font-size: 1.2rem;
            opacity: 0.5;
          }
        }
      }
    }
  }

  .actives {
    transform: translate(0%);
    transition: 0.8s;
  }
}