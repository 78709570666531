@import "./assets/Scss/Configration/Mixin.scss";

*{
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-style: normal;
}

h1,h2,h3,h4,h5,h6,p{
  margin: 0;

  padding: 0;
}

ul{
  padding: 0;
  margin: 0;
}

li{
  margin: 0;
  padding: 0;
}

a{
  text-decoration: none;
}

// Typography.scss or Nav.module.scss




// h2 {
//   font-size: responsive-font-size(2.5); // Base size of 2.5rem
// }

// h3 {
//   font-size: responsive-font-size(2); // Base size of 2rem
// }

// h4 {
//   font-size: responsive-font-size(1.75); // Base size of 1.75rem
// }

// h5 {
//   font-size: responsive-font-size(1.5); // Base size of 1.5rem
// }

// h6 {
//   font-size: responsive-font-size(1.25); // Base size of 1.25rem
// }

