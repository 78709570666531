@import "../Configration/Veriabales.scss";
@import "../Configration/Mixin.scss";

/* Footer Container */
.footer {
    background-color: #091a36;
    color: white;
    padding: 30px;
    font-family: Arial, sans-serif;
  }
  
  .footerContainer {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    column-gap: 2rem;
    align-items: flex-start;

  }
  
  /* Footer Left Section */
  .footerLeft {
    max-width: 300px;
  }
  
  .footerLogo {
    width: 100%;
    margin-bottom: 1.5rem;
    margin-top: 2rem;
  }
  
  .footerLeft h2 {
    color: #00aedb;
    margin: 0;
  }
  
  .footerLeft p {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 2rem;
    color:#bcbaba;
    

  }
  
  .sosialmidia {

    @include flex-center(row, flex-start);
   
      list-style: none;
      padding: 0;
  
  
  li {
      margin-right: $spacing-unit;
      @include responsive(margin-right, $spacing-unit * 0.5);
      a {
          i {
              font-size: responsive-font-size(1.5);
              color: $pry-color;
              padding: 0.8rem 1rem;
              background-color: #0097ee3a;
              transition: all 0.4s ease-in-out;
              border-radius: 5px;
    
              &:hover{
                  background-color: $pry-color;
                  color: $white-color;
                  transform: scale(1.1);
              }
    
          }
      }
  }

    // margin: 0 $spacing-unit;
}
  
  .socialIcons .icon {
    width: 40px;
    height: 40px;
    background-color: #b0b0b0;
    border-radius: 5px;
  }
  
  /* Footer Middle Section */

  .footerMiddle{
    margin-top: 3rem;
    margin-left: 2rem;
  }
  h3 {
    margin-bottom: 10px;
    font-size: 18px;
  }
  
  .footerMiddle p {
    font-size: 12px;
    // line-height: 2rem;
    margin-bottom: 0.5rem;
    color:#bcbaba;
    cursor: pointer;
    &:hover{
      color: $pry-color;
      text-decoration: underline;
    }
    
  }
  
  /* Footer Right Section */
  .footerRight{
    margin-top: 3rem;
    margin-left: 2rem;

  }
  .footerRight h3 {
    margin-bottom: 10px;
    font-size: 18px;

  }
  
  .emergencyNumber {
    font-size: 28px;
    font-weight: bold;
    color: $pry-color;
    text-decoration: none;
  }
  
  /* Footer Bottom */
  .footerBottom {
    text-align: center;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    border-top: 1px solid #6f6d6d62;
  }
  
  .footerBottom p {
    margin: 0;
    margin-top: 1rem ;
    padding: 0;
    color:#bcbaba;
    font-size: 14px;

    a{
      color:#fff;
      text-decoration: underline;
      margin-right: 3rem;
    }

  }
  
  @media screen and (max-width:993px) {
    /* Footer Container */
.footer {
  background-color: #091a36;
  color: white;
  padding: 30px;
  font-family: Arial, sans-serif;
}

.footerContainer {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  column-gap: 2rem;
  align-items: flex-start;

}

/* Footer Left Section */
.footerLeft {
  max-width: 300px;
}

.footerLogo {
  width: 100%;
  margin-bottom: 10px;
}

.footerLeft h2 {
  color: #00aedb;
  margin: 0;
}

.footerLeft p {
  font-size: 12px;
  line-height: 1.6;
  margin-bottom: 2rem;
  color:#bcbaba

}

.sosialmidia {
  @include flex-center(row, flex-start);
 
    list-style: none;
    padding: 0;


li {
    margin-right: $spacing-unit;
    @include responsive(margin-right, $spacing-unit * 0.5);
    a {
        i {
            font-size: responsive-font-size(1);
            color: $pry-color;
            padding: 0.8rem 1rem;
            background-color: #0097ee3a;
            transition: all 0.4s ease-in-out;
            border-radius: 5px;
  
            &:hover{
                background-color: $pry-color;
                color: $white-color;
                transform: scale(1.1);
            }
  
        }
    }
}

  // margin: 0 $spacing-unit;
}

.socialIcons .icon {
  width: 40px;
  height: 40px;
  background-color: #b0b0b0;
  border-radius: 5px;
}

/* Footer Middle Section */

.footerMiddle{
  margin-top: 3rem;
  margin-left: 0;
}
 h3 {
  margin-bottom: 10px;
  font-size: 16px;
}

.footerMiddle p {
  font-size: 12px;
  // line-height: 2rem;
  margin-bottom: 0.5rem;
  color:#bcbaba
}

/* Footer Right Section */
.footerRight{
  margin-top: 3rem;
  margin-left: 0;

}
.footerRight h3 {
  margin-bottom: 10px;
  font-size: 14px;

}

.emergencyNumber {
  font-size: 20px;
  font-weight: bold;
  color: $pry-color;
  text-decoration: none;
}

/* Footer Bottom */
.footerBottom {
  text-align: center;
  flex-direction: column;
  margin-top: 30px;
  border-top: 1px solid #6f6d6d62;
}

.footerBottom p {
  margin: 0;
  margin-top: 1rem ;
  padding: 0;
  color:#bcbaba;
  font-size: 12px;
  font-size: 12px;
  a{
    margin-right: 0;
  }

}

  }


  @media screen and (max-width:467px) {
    /* Footer Container */
.footer {
  background-color: #091a36;
  color: white;
  padding: 30px 0;
  font-family: Arial, sans-serif;
}

.footerContainer {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  column-gap: 1rem;
  align-items: flex-start;

}

/* Footer Left Section */
.footerLeft {
  max-width: 300px;
}

.footerLogo {
  width: 80%;
  margin-bottom: 10px;
  margin-top: 3rem;
}

.footerLeft h2 {
  color: #00aedb;
  margin: 0;
}

.footerLeft p {
  font-size: 10px;
  line-height: 1.6;
  margin-bottom: 1rem;
  color:#bcbaba

}

.sosialmidia {
  @include flex-center(row, flex-start);
 
    list-style: none;
    padding: 0;


li {
    margin-right: $spacing-unit;
    @include responsive(margin-right, $spacing-unit * 0.5);
    a {
        i {
            font-size: responsive-font-size(1);
            color: $pry-color;
            padding: 0.8rem 1rem;
            background-color: #0097ee3a;
            transition: all 0.4s ease-in-out;
            border-radius: 5px;
  
            &:hover{
                background-color: $pry-color;
                color: $white-color;
                transform: scale(1.1);
            }
  
        }
    }
}

  // margin: 0 $spacing-unit;
}

.socialIcons .icon {
  width: 40px;
  height: 40px;
  background-color: #b0b0b0;
  border-radius: 5px;
}

/* Footer Middle Section */

.footerMiddle{
  margin-top: 3rem;
  margin-left: 0;
}
 h3 {
  margin-bottom: 10px;
  font-size: 14px;
}

.footerMiddle p {
  font-size: 10px;
  // line-height: 2rem;
  margin-bottom: 0.2rem;
  color:#bcbaba
}

/* Footer Right Section */
.footerRight{
  margin-top: 3rem;
  margin-left: 0;

}
.footerRight h3 {
  margin-bottom: 0.5px;
  font-size: 14px;

}

.emergencyNumber {
  font-size: 16px;
  font-weight: bold;
  color: $pry-color;
  text-decoration: none;
  margin-top: 1rem;
}

/* Footer Bottom */
.footerBottom {
  flex-direction: column;
  text-align: center;
  margin-top: 30px;
  border-top: 1px solid #6f6d6d62;
}

.footerBottom p {
  margin: 0;
  margin-top: 1rem ;
  padding: 0;
  color:#bcbaba;
  font-size: 12px;
  font-size: 12px;
  a{
    margin-right: 0;
  }

}

  }