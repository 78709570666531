@import "../Configration/Veriabales.scss";
@import "../Configration/Mixin.scss";

.banner {
  background-image: url('../../images/banner_bg.webp');
  background-size: cover;
  background-position: center;
  height: 80vh;
  position: relative;
  object-fit: contain;

}


.info {
  text-align: left;
  color: white;
  width: 60%;
  padding-top: 5rem;

  h1 {
    font-size: 3.5rem;
    font-weight: 800;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    color: $sec-color;
  }

  p {
    font-size: responsive-font-size(1.3);
    margin-bottom: 2rem;
    color: $sec-color;
    opacity: 0.7;
  }
}

.em_book {
  position: relative;
  // bottom: -55%;
  left: 0;
  right: 0;
  top: -30px;

  .em_wrp {
    // background: red;
    // @include flex-center(row, space-between, center)
    display: grid;
    grid-template-columns: 1.5fr 4fr;
    column-gap: 2rem;


    .em_info {
      border-radius: 10px;
      padding: 2rem;
      box-shadow: 0 0 20px #00000042;
      background: linear-gradient(90deg, rgba(9, 9, 121, 0.913) 0%, rgba(9, 9, 121, 0.913) 100%), url("https://img.freepik.com/free-photo/attractive-female-doctor-standing-with-documents-hospital_1303-20688.jpg?t=st=1726698046~exp=1726701646~hmac=6697488243bfbb289ccc5955c8d3d301d76e072d52f519a115b609c4bdc567ba&w=826");
      background-size: contain;
      object-fit: contain;
      color: $white-color;

      .em_wrp {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        // align-items: flex-start;
        i {
          font-size: 38px;
          color: $pry-color;
        }

        p {
          font-size: 14px;
          margin: 0;
          margin-bottom: 0.5rem;
        }

        h3 {
          font-size: 18px;
          font-weight: 600;
          color: $pry-color;
        }
      }

      p {
        font-size: 14px;
        margin: 2rem 0;
      }

      button {
        padding: 0.5rem 1rem;
        font-size: 16px;

      }
    }

    .booking_box {
      background-color: #fff;
      background: url("../../images/em_bg.webp");
      border-radius: 10px;
      padding: 2rem;
      box-shadow: 0 0 20px #00000042;
      background-position: right;
      background-size: cover;
      background-repeat: no-repeat;

      ul {
        @include flex-center(column)
      }

      // overflow: hidden;
      // .spna{
      //   position: absolute;
      //   right: 0%;
      //   height: 100%;
      //   bottom: 0;
      //   top: 50%;
      //   width: 20%;
      //   left: 80%;
      //   z-index: 1;
      //   img{
      //     width: 100%;
      //   }
      // }
      h1 {
        font-size: responsive-font-size(2);
        font-weight: 600;
        margin: $spacing-unit 0;
        color: $pry-color;
      }

      form {


        .form__contorl {

          display: grid;
          grid-template-columns: 1fr 1fr;
          align-items: center;
          column-gap: 2rem;
          margin-bottom: 2rem;

          .box {
            // width: 50%;

          }

        }
      }

      .btn {
        display: inline-block;
        padding: .75rem 2rem;
        border: none;
        color: #fff;
        text-transform: uppercase;
        font-size: responsive-font-size(1);
        transition: all .3s;
        position: relative;
        overflow: hidden;
        z-index: 1;

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: $pry-color;

          z-index: -2;
        }

        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0%;
          height: 100%;
          background-color: darken($pry-color, 15%);
          transition: all .3s;

          z-index: -1;
        }

        &:hover {
          color: #fff;

          &:before {
            width: 100%;
          }
        }
      }


    }

  }


}

.modalContainer {
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  box-shadow: 24;
  padding: 30px;
  border-radius: 10px;

  h4 {
    font-size: 20px;
    font-weight: 500;
  }
}

.modalTitle {
  color: #000000;
  font-size: 21px;
  font-weight: 600;
}

.modalSubtitle {
  font-weight: 400;
  font-size: 14px;
  color: #918E8E;
  padding-top: 10px;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 2rem 0;
}

.inputField {
  border-radius: 10px;
}

.weekContainer {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.dayLink {
  cursor: pointer;
  background-color: #D1EEFF69;
  padding: 0.5rem 1rem;
  border-radius: 50px;
  margin-bottom: 10px;
  text-align: center; // Optional for visual indication
  transition: background-color 0.3s;
  border: 1px solid $sec-color;
  color: $sec-color;

  &:hover {
    background-color: $pry-color; // Change this to your desired color
    color: $white-color;
  }
}

.selectedDay {
  background-color: $pry-color; // Change this to your desired color
  color: $white-color; // Change text color if needed
}

.timeSlots {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  column-gap: 2rem;
  // justify-content: center;
}

.timeSlot {
  background-color: #D1EEFF69;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  text-align: center;
}

.selectedSlot {
  border: 2px solid $pry-color; // Highlight selected slot
}

.bookButton {
  margin-top: 20px;
  display: inline-block;
  padding: .75rem 2rem;
  border: none;
  color: #fff;
  text-transform: uppercase;
  font-size: responsive-font-size(1);
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $pry-color;

    z-index: -2;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: darken($pry-color, 15%);
    transition: all .3s;

    z-index: -1;
  }

  &:hover {
    color: #fff;

    &:before {
      width: 100%;
    }
  }
}



.about {
  padding: 5rem 0;
  position: relative;

  .spna {
    position: absolute;
    z-index: -1;
    top: -50px;
  }

  .about_wrp {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .about_info {

      // background-image: url("../../images/scap_2.webp");
      // background-position: left;
      // background-repeat: no-repeat;
      h5 {
        font-size: responsive-font-size(1);
        color: $pry-color;
        margin-bottom: $spacing-unit;
        text-transform: uppercase;
      }

      h1 {
        font-size: responsive-font-size(3);
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      p {
        margin-top: 2rem;
      }

      h4 {
        font-size: responsive-font-size(1.5);
        margin: 2rem 0;
        font-weight: 500;
      }

      .certy_wrp {

        max-width: 700px;
        width: 100%;
        img {
          width: 100%;
          padding: 0.5rem;
          transition: all 0.4s ease-in-out;
          &:hover{
            transform: scale(1.2);
          }
        }

      }
    }

    .img {
      align-items: flex-end;
      text-align: end;

      img {
        width: 60%;
      }

      .big_img {
        // width: 60%;
        margin-right: 5rem;
      }

      .small_img {
        position: absolute;
        position: relative;
        width: 60%;
        right: -40%;
        top: -10%;

      }
    }

  }


}


.deepartment {
  padding: 5rem 0;
  background: url("..//../images/snap_bg.webp");
  background-position: center;
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;

  .heading {
    text-align: center;
    margin-bottom: 5rem;

    h1 {
      font-size: responsive-font-size(2);
      font-weight: 700;
      text-transform: uppercase;
      padding: 2rem 0;
    }
  }

  .slider {
    position: relative;
    margin-top: 8rem;

    .arrow {
      position: absolute;
      top: -50px;
      left: 90%;
      z-index: 9999;
      cursor: pointer;
      background-color: $pry-color;
      padding: 0.5rem 0.9rem;
      border-radius: 50px;
      color: $white-color;


    }

    .next {
      font-size: 24px;
      font-weight: 600;
      left: 95%;

    }

    .prev {
      font-size: 24px;
      font-weight: 600;
      left: 89%;
      // background-color: red;

    }
  }

  .box {
    padding: 0.5rem;
    margin-top: 3rem;
  }

  .itme {
    padding: 1rem;
    border: 1px solid #00000020;
    height: 100%;
    border-radius: 5px;

    button {
      background: transparent !important;
      padding: 0;
      color: $pry-color;
      font-size: responsive-font-size(0.5);
      border: none;

      i {
        transition: all 0.6s ease-in-out;

      }

      &:hover i {
        padding-left: 1rem;

      }
    }

    img {
      width: 100%;
      object-fit: contain;
      margin-bottom: $spacing-unit;
    }

    h3 {
      font-size: responsive-font-size(1.2);
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    p {
      font-size: 14px;
      width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 1.5rem 0;

    }

    a {
      font-size: resp;
    }
  }
}

.doctor {
  padding: 5rem 0;
  position: relative;

  .snap {
    position: absolute;
    z-index: -1;
    top: -60px;

    img {
      width: 80%;
    }
  }

  .slider {
    margin: 5rem 0 0rem;
    position: relative;
    margin-top: 8rem;

    .arrow {
      position: absolute;
      top: -80px;
      left: 90%;
      z-index: 9999;
      cursor: pointer;
      background-color: $pry-color;
      padding: 0.5rem 0.9rem;
      border-radius: 50px;
      color: $white-color;


    }

    .next {
      font-size: 24px;
      font-weight: 600;
      left: 95%;

    }

    .prev {
      font-size: 24px;
      font-weight: 600;
      left: 89%;
      // background-color: red;

    }
  }

  .span {
    position: absolute;
    top: 60%;
    left: 0;
    right: 0;
    z-index: -1;

    img {
      width: 100%;
    }
  }

}


@media screen and (max-width:1240px) {
  .banner {
    background-image: url('../../images/banner_bg.webp');
    background-size: cover;
    background-position: center;
    height: 100vh;
    position: relative;
    object-fit: contain;

  }


  .info {
    text-align: left;
    color: white;
    width: 60%;
    padding-top: 5rem;

    h1 {
      font-size: 3.5rem;
      font-weight: 800;
      margin-bottom: 1.5rem;
      text-transform: uppercase;
      color: $sec-color;
    }

    p {
      font-size: responsive-font-size(1.3);
      margin-bottom: 2rem;
      color: $sec-color;
      opacity: 0.7;
    }
  }

  .em_book {
    position: relative;
    // bottom: -55%;
    left: 0;
    right: 0;
    top: -30px;

    .em_wrp {
      // background: red;
      // @include flex-center(row, space-between, center)
      display: grid;
      grid-template-columns: 1.5fr 4fr;
      column-gap: 2rem;


      .em_info {
        border-radius: 10px;
        padding: 2rem 1rem;
        box-shadow: 0 0 20px #00000042;
        background: linear-gradient(90deg, rgba(9, 9, 121, 0.913) 0%, rgba(9, 9, 121, 0.913) 100%), url("https://img.freepik.com/free-photo/attractive-female-doctor-standing-with-documents-hospital_1303-20688.jpg?t=st=1726698046~exp=1726701646~hmac=6697488243bfbb289ccc5955c8d3d301d76e072d52f519a115b609c4bdc567ba&w=826");
        background-size: contain;
        object-fit: contain;
        color: $white-color;

        .em_wrp {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;

          // align-items: flex-start;
          i {
            font-size: 28px;
            color: $pry-color;
          }

          p {
            font-size: 12px;
            margin: 0;
            margin-bottom: 0.5rem;
          }

          h3 {
            font-size: 16px;
            font-weight: 600;
            color: $pry-color;
          }
        }

        p {
          font-size: 12px;
          margin: 2rem 0;
        }

        button {
          padding: 0.5rem 1rem;
          font-size: 16px;

        }
      }

      .booking_box {
        background-color: #fff;
        background: url("../../images/em_bg.webp");
        border-radius: 10px;
        padding: 2rem;
        box-shadow: 0 0 20px #00000042;
        background-position: right;
        background-size: cover;
        background-repeat: no-repeat;

        ul {
          @include flex-center(column)
        }

        // overflow: hidden;
        // .spna{
        //   position: absolute;
        //   right: 0%;
        //   height: 100%;
        //   bottom: 0;
        //   top: 50%;
        //   width: 20%;
        //   left: 80%;
        //   z-index: 1;
        //   img{
        //     width: 100%;
        //   }
        // }
        h1 {
          font-size: responsive-font-size(2);
          font-weight: 600;
          margin: $spacing-unit 0;
          color: $pry-color;
        }

        form {


          .form__contorl {

            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            column-gap: 2rem;
            margin-bottom: 2rem;

            .box {
              // width: 50%;

            }

          }
        }

        .btn {
          display: inline-block;
          padding: .75rem 2rem;
          border: none;
          color: #fff;
          text-transform: uppercase;
          font-size: responsive-font-size(1);
          transition: all .3s;
          position: relative;
          overflow: hidden;
          z-index: 1;

          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $pry-color;

            z-index: -2;
          }

          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0%;
            height: 100%;
            background-color: darken($pry-color, 15%);
            transition: all .3s;

            z-index: -1;
          }

          &:hover {
            color: #fff;

            &:before {
              width: 100%;
            }
          }
        }


      }

    }


  }

  .modalContainer {
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    box-shadow: 24;
    padding: 30px;
    border-radius: 10px;

    h4 {
      font-size: 20px;
      font-weight: 500;
    }
  }

  .modalTitle {
    color: #000000;
    font-size: 21px;
    font-weight: 600;
  }

  .modalSubtitle {
    font-weight: 400;
    font-size: 14px;
    color: #918E8E;
    padding-top: 10px;
  }

  .inputContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin: 2rem 0;
  }

  .inputField {
    border-radius: 10px;
  }

  .weekContainer {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }

  .dayLink {
    cursor: pointer;
    background-color: #D1EEFF69;
    padding: 0.5rem 1rem;
    border-radius: 50px;
    margin-bottom: 10px;
    text-align: center; // Optional for visual indication
    transition: background-color 0.3s;
    border: 1px solid $sec-color;
    color: $sec-color;

    &:hover {
      background-color: $pry-color; // Change this to your desired color
      color: $white-color;
    }
  }

  .selectedDay {
    background-color: $pry-color; // Change this to your desired color
    color: $white-color; // Change text color if needed
  }

  .timeSlots {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    column-gap: 2rem;
    // justify-content: center;
  }

  .timeSlot {
    background-color: #D1EEFF69;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    text-align: center;
  }

  .selectedSlot {
    border: 2px solid $pry-color; // Highlight selected slot
  }

  .bookButton {
    margin-top: 20px;
    display: inline-block;
    padding: .75rem 2rem;
    border: none;
    color: #fff;
    text-transform: uppercase;
    font-size: responsive-font-size(1);
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $pry-color;

      z-index: -2;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: darken($pry-color, 15%);
      transition: all .3s;

      z-index: -1;
    }

    &:hover {
      color: #fff;

      &:before {
        width: 100%;
      }
    }
  }



  .about {
    padding: 5rem 0;
    position: relative;

    .spna {
      position: absolute;
      z-index: -1;
      top: -50px;
    }

    .about_wrp {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .about_info {

        // background-image: url("../../images/scap_2.webp");
        // background-position: left;
        // background-repeat: no-repeat;
        h5 {
          font-size: responsive-font-size(1);
          color: $pry-color;
          margin-bottom: $spacing-unit;
          text-transform: uppercase;
        }

        h1 {
          font-size: responsive-font-size(3);
          font-weight: 800;
          text-transform: uppercase;
          letter-spacing: 1px;
        }

        p {
          margin-top: 2rem;
        }

        h4 {
          font-size: responsive-font-size(1.5);
          margin: 2rem 0;
          font-weight: 500;
        }

        .certy_wrp {
        max-width: 600px;
        width: 100%;

          img {
            width: 100%;
          }

        }
      }

      .img {
        align-items: flex-end;
        text-align: end;

        img {
          width: 60%;
        }

        .big_img {
          // width: 60%;
          margin-right: 5rem;

        }

        .small_img {
          position: absolute;
          position: relative;
          width: 60%;
          right: -40%;
          top: -10%;

        }
      }

    }


  }

  .deepartment {
    padding: 5rem 0;
    background: url("..//../images/snap_bg.webp");
    background-position: center;
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;

    .heading {
      text-align: center;
      margin-bottom: 5rem;

      h1 {
        font-size: responsive-font-size(2);
        font-weight: 700;
        text-transform: uppercase;
        padding: 2rem 0;
      }
    }

    .slider {
      position: relative;
      margin-top: 8rem;

      .arrow {
        position: absolute;
        top: -50px;
        left: 90%;
        z-index: 9999;
        cursor: pointer;
        background-color: $pry-color;
        padding: 0.5rem 0.9rem;
        border-radius: 50px;
        color: $white-color;


      }

      .next {
        font-size: 24px;
        font-weight: 600;
        left: 95%;

      }

      .prev {
        font-size: 24px;
        font-weight: 600;
        left: 89%;
        // background-color: red;

      }
    }

    .box {
      padding: 0.5rem;
      margin-top: 3rem;
    }

    .itme {
      padding: 1rem;
      border: 1px solid #00000020;
      height: 100%;
      border-radius: 5px;
    }
  }

  .doctor {
    padding: 5rem 0;
    position: relative;

    .snap {
      position: absolute;
      z-index: -1;
      top: -60px;

      img {
        width: 80%;
      }
    }

    .slider {
      margin: 5rem 0 0rem;
      position: relative;
      margin-top: 8rem;

      .arrow {
        position: absolute;
        top: -80px;
        left: 90%;
        z-index: 9999;
        cursor: pointer;
        background-color: $pry-color;
        padding: 0.5rem 0.9rem;
        border-radius: 50px;
        color: $white-color;


      }

      .next {
        font-size: 24px;
        font-weight: 600;
        left: 95%;

      }

      .prev {
        font-size: 24px;
        font-weight: 600;
        left: 89%;
        // background-color: red;

      }
    }

    .span {
      position: absolute;
      top: 70%;
      left: 0;
      right: 0;
      z-index: -1;

      img {
        width: 100%;
      }
    }

  }
}


@media screen and (max-width:992px) {
  .banner {
    background-image: url('../../images/banner_bg.webp');
    background-size: cover;
    background-position: center;
    height: 80vh;
    position: relative;
    object-fit: contain;

  }


  .info {
    padding-top: 3rem;

    h1 {
      font-size: responsive-font-size(5);
    }

    p {
      font-size: 12px;
      margin-bottom: 0;
      opacity: 0.8;
    }
  }

  .em_book {
    position: relative;
    // bottom: -55%;
    left: 0;
    right: 0;
    top: 0;
    padding: 3rem 0;

    .em_wrp {
      // background: red;
      // @include flex-center(row, space-between, center)
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 2rem;
      gap: 2rem;

      .em_info {
        border-radius: 10px;
        padding: 2rem 2rem 0;
        box-shadow: none;
        background: linear-gradient(90deg, rgba(9, 9, 121, 0.913) 0%, rgba(9, 9, 121, 0.913) 100%), url("https://img.freepik.com/free-photo/attractive-female-doctor-standing-with-documents-hospital_1303-20688.jpg?t=st=1726698046~exp=1726701646~hmac=6697488243bfbb289ccc5955c8d3d301d76e072d52f519a115b609c4bdc567ba&w=826");
        background-size: contain;
        object-fit: contain;
        color: $white-color;

        .em_wrp {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;

          // align-items: flex-start;
          i {
            font-size: 48px;
            color: $pry-color;
          }

          p {
            font-size: 14px;
            margin: 0;
            margin-bottom: 0.5rem;
          }

          h3 {
            font-size: 22px;
            font-weight: 600;
            color: $pry-color;
          }
        }

        p {
          font-size: 14px;
          margin: 2rem 0;
        }

        button {
          padding: 0.5rem 1rem;
          font-size: 16px;

        }
      }

      .booking_box {
        background-color: #fff;
        background: url("../../images/em_bg.webp");
        border-radius: 10px;
        padding: 1rem;
        box-shadow: 0 0 20px #00000000;
        background-position: right;
        background-size: cover;
        background-repeat: no-repeat;
        border: 1px solid #aaa9a9;

        ul {
          @include flex-center(column)
        }

        // overflow: hidden;
        // .spna{
        //   position: absolute;
        //   right: 0%;
        //   height: 100%;
        //   bottom: 0;
        //   top: 50%;
        //   width: 20%;
        //   left: 80%;
        //   z-index: 1;
        //   img{
        //     width: 100%;
        //   }
        // }
        h1 {
          font-size: responsive-font-size(2.5);
          font-weight: 600;
          // margin: $spacing-unit 0;
          margin: 0;
          margin-bottom: 1rem;
          color: $pry-color;
        }

        form {


          .form__contorl {

            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            column-gap: 2rem;
            margin-bottom: 1rem;

            .box {
              // width: 50%;

            }

          }
        }

        .btn {
          display: inline-block;
          padding: .75rem 2rem;
          border: none;
          color: #fff;
          text-transform: uppercase;
          font-size: responsive-font-size(1);
          transition: all .3s;
          position: relative;
          overflow: hidden;
          z-index: 1;

          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $pry-color;

            z-index: -2;
          }

          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0%;
            height: 100%;
            background-color: darken($pry-color, 15%);
            transition: all .3s;

            z-index: -1;
          }

          &:hover {
            color: #fff;

            &:before {
              width: 100%;
            }
          }
        }


      }

    }


  }

  .modalContainer {
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    box-shadow: 24;
    padding: 30px;
    border-radius: 10px;

    h4 {
      font-size: 20px;
      font-weight: 500;
    }
  }

  .modalTitle {
    color: #000000;
    font-size: 21px;
    font-weight: 600;
  }

  .modalSubtitle {
    font-weight: 400;
    font-size: 14px;
    color: #918E8E;
    padding-top: 10px;
  }

  .inputContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin: 2rem 0;
  }

  .inputField {
    border-radius: 10px;
  }

  .weekContainer {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }

  .dayLink {
    cursor: pointer;
    background-color: #D1EEFF69;
    padding: 0.5rem 1rem;
    border-radius: 50px;
    margin-bottom: 10px;
    text-align: center; // Optional for visual indication
    transition: background-color 0.3s;
    border: 1px solid $sec-color;
    color: $sec-color;

    &:hover {
      background-color: $pry-color; // Change this to your desired color
      color: $white-color;
    }
  }

  .selectedDay {
    background-color: $pry-color; // Change this to your desired color
    color: $white-color; // Change text color if needed
  }

  .timeSlots {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    column-gap: 2rem;
    // justify-content: center;
  }

  .timeSlot {
    background-color: #D1EEFF69;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    text-align: center;
  }

  .selectedSlot {
    border: 2px solid $pry-color; // Highlight selected slot
  }

  .bookButton {
    margin-top: 20px;
    display: inline-block;
    padding: .75rem 2rem;
    border: none;
    color: #fff;
    text-transform: uppercase;
    font-size: responsive-font-size(1);
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $pry-color;

      z-index: -2;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: darken($pry-color, 15%);
      transition: all .3s;

      z-index: -1;
    }

    &:hover {
      color: #fff;

      &:before {
        width: 100%;
      }
    }
  }



  .about {
    padding: 2rem 0;
    position: relative;

    .spna {
      position: absolute;
      z-index: -1;
      top: -50px;

      img {
        width: 50%;
      }
    }

    .about_wrp {
      display: flex;
      flex-direction: column-reverse;
      grid-template-columns: 1fr;
      align-items: center;

      .about_info {

        // background-image: url("../../images/scap_2.webp");
        // background-position: left;
        // background-repeat: no-repeat;
        h5 {
          font-size: responsive-font-size(1);
          color: $pry-color;
          margin-bottom: $spacing-unit;
          text-transform: uppercase;
          margin-top: 2rem;
        }

        h1 {
          font-size: responsive-font-size(3);
          font-weight: 800;
          text-transform: uppercase;
          letter-spacing: 1px;
        }

        p {
          margin-top: 2rem;
          font-size: 12px;
        }

        h4 {
          font-size: responsive-font-size(2);
          margin: 2rem 0;
          font-weight: 500;
        }

        .certy_wrp {

        max-width: 700px;
        padding: 0 ;

          img {
            width: 100%;
          }

        }
      }

      .img {
        align-items: flex-end;
        text-align: end;

        img {
          width: 100%;
        }

        .big_img {
          // width: 60%;
          margin-right: 5rem;
          display: none;
        }

        .small_img {
          position: relative;
          width: 100%;
          right: 0;
          top: 0;
          object-fit: cover;

        }
      }

    }


  }


  .deepartment {
    padding: 3rem 0;
    background: url("..//../images/snap_bg.webp");
    background-position: center;
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;

    .heading {
      text-align: center;
      margin-bottom: 5rem;

      h1 {
        font-size: responsive-font-size(1.5);
        font-weight: 700;
        text-transform: uppercase;
        padding: 2rem 0;
      }

      p {
        font-size: 12px;
      }
    }

    .slider {
      position: relative;
      margin-top: 1rem;

      .arrow {
        position: absolute;
        top: 0px;
        left: 90%;
        z-index: 1;
        cursor: pointer;
        background-color: $pry-color;
        padding: 0.4rem 0.6rem;
        border-radius: 50px;
        color: $white-color;


      }

      .next {
        font-size: 16px;
        font-weight: 600;
        left: 90%;

      }

      .prev {
        font-size: 16px;
        font-weight: 600;
        left: 80%;
        // background-color: red;

      }
    }

    .box {
      padding: 0.5rem;
      margin-top: 3rem;
    }

    .itme {
      padding: 1rem;
      border: 1px solid #00000020;
      height: 100%;
      border-radius: 5px;

      button {
        background: transparent !important;
        padding: 0;
        color: $pry-color;
        font-size: 14px;
        border: none;

        i {
          transition: all 0.6s ease-in-out;

        }

        &:hover i {
          padding-left: 1rem;

        }
      }

      img {
        width: 100%;
        object-fit: contain;
        margin-bottom: $spacing-unit;
      }

      h3 {
        font-size: responsive-font-size(1.1);
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      p {
        font-size: 12px;
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 1.5rem 0;


      }


    }
  }

  .doctor {
    padding: 2rem 0;
    position: relative;

    .snap {
      position: absolute;
      z-index: -1;
      top: -60px;

      img {
        width: 50%;
      }
    }

    .slider {
      position: relative;
      margin-top: 5rem;

      .arrow {
        position: absolute;
        top: -50px;
        left: 90%;
        z-index: 1;
        cursor: pointer;
        background-color: $pry-color;
        padding: 0.4rem 0.6rem;
        border-radius: 50px;
        color: $white-color;


      }

      .next {
        font-size: 16px;
        font-weight: 600;
        left: 90%;

      }

      .prev {
        font-size: 16px;
        font-weight: 600;
        left: 80%;
        // background-color: red;

      }
    }

    .span {
      position: absolute;
      top: 73%;
      left: 0;
      right: 0;
      z-index: -1;

      img {
        width: 100%;
      }
    }

  }


}

@media screen and (max-width:767px) {
  .banner {
    background-image: url('../../images/banner_bg.webp');
    background-size: cover;
    background-position: center;
    height: 80vh;
    position: relative;
    object-fit: contain;

  }

  .info {
    padding-top: 3rem;

    h1 {
      font-size: responsive-font-size(5);
    }

    p {
      font-size: 12px;
      margin-bottom: 0;
      opacity: 0.8;
    }
  }

  .em_book {
    position: relative;
    // bottom: -55%;
    left: 0;
    right: 0;
    top: 0;
    padding: 3rem 0;

    .em_wrp {
      // background: red;
      // @include flex-center(row, space-between, center)
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 2rem;
      gap: 2rem;

      .em_info {
        border-radius: 10px;
        padding: 2rem 2rem 0;
        box-shadow: none;
        background: linear-gradient(90deg, rgba(9, 9, 121, 0.913) 0%, rgba(9, 9, 121, 0.913) 100%), url("https://img.freepik.com/free-photo/attractive-female-doctor-standing-with-documents-hospital_1303-20688.jpg?t=st=1726698046~exp=1726701646~hmac=6697488243bfbb289ccc5955c8d3d301d76e072d52f519a115b609c4bdc567ba&w=826");
        background-size: contain;
        object-fit: contain;
        color: $white-color;

        .em_wrp {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;

          // align-items: flex-start;
          i {
            font-size: 48px;
            color: $pry-color;
          }

          p {
            font-size: 14px;
            margin: 0;
            margin-bottom: 0.5rem;
          }

          h3 {
            font-size: 22px;
            font-weight: 600;
            color: $pry-color;
          }
        }

        p {
          font-size: 14px;
          margin: 2rem 0;
        }

        button {
          padding: 0.5rem 1rem;
          font-size: 16px;

        }
      }

      .booking_box {
        background-color: #fff;
        background: url("../../images/em_bg.webp");
        border-radius: 10px;
        padding: 1rem;
        box-shadow: 0 0 20px #00000000;
        background-position: right;
        background-size: cover;
        background-repeat: no-repeat;
        border: 1px solid #aaa9a9;

        ul {
          @include flex-center(column)
        }

        // overflow: hidden;
        // .spna{
        //   position: absolute;
        //   right: 0%;
        //   height: 100%;
        //   bottom: 0;
        //   top: 50%;
        //   width: 20%;
        //   left: 80%;
        //   z-index: 1;
        //   img{
        //     width: 100%;
        //   }
        // }
        h1 {
          font-size: responsive-font-size(2.5);
          font-weight: 600;
          // margin: $spacing-unit 0;
          margin: 0;
          margin-bottom: 1rem;
          color: $pry-color;
        }

        form {


          .form__contorl {

            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            column-gap: 2rem;
            margin-bottom: 1rem;

            .box {
              // width: 50%;

            }

          }
        }

        .btn {
          display: inline-block;
          padding: .75rem 2rem;
          border: none;
          color: #fff;
          text-transform: uppercase;
          font-size: responsive-font-size(1);
          transition: all .3s;
          position: relative;
          overflow: hidden;
          z-index: 1;

          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $pry-color;

            z-index: -2;
          }

          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0%;
            height: 100%;
            background-color: darken($pry-color, 15%);
            transition: all .3s;

            z-index: -1;
          }

          &:hover {
            color: #fff;

            &:before {
              width: 100%;
            }
          }
        }


      }

    }


  }

  .modalContainer {
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    box-shadow: 24;
    padding: 30px;
    border-radius: 10px;

    h4 {
      font-size: 20px;
      font-weight: 500;
    }
  }

  .modalTitle {
    color: #000000;
    font-size: 21px;
    font-weight: 600;
  }

  .modalSubtitle {
    font-weight: 400;
    font-size: 14px;
    color: #918E8E;
    padding-top: 10px;
  }

  .inputContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin: 2rem 0;
  }

  .inputField {
    border-radius: 10px;
  }

  .weekContainer {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }

  .dayLink {
    cursor: pointer;
    background-color: #D1EEFF69;
    padding: 0.5rem 1rem;
    border-radius: 50px;
    margin-bottom: 10px;
    text-align: center; // Optional for visual indication
    transition: background-color 0.3s;
    border: 1px solid $sec-color;
    color: $sec-color;

    &:hover {
      background-color: $pry-color; // Change this to your desired color
      color: $white-color;
    }
  }

  .selectedDay {
    background-color: $pry-color; // Change this to your desired color
    color: $white-color; // Change text color if needed
  }

  .timeSlots {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    column-gap: 2rem;
    // justify-content: center;
  }

  .timeSlot {
    background-color: #D1EEFF69;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    text-align: center;
  }

  .selectedSlot {
    border: 2px solid $pry-color; // Highlight selected slot
  }

  .bookButton {
    margin-top: 20px;
    display: inline-block;
    padding: .75rem 2rem;
    border: none;
    color: #fff;
    text-transform: uppercase;
    font-size: responsive-font-size(1);
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $pry-color;

      z-index: -2;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: darken($pry-color, 15%);
      transition: all .3s;

      z-index: -1;
    }

    &:hover {
      color: #fff;

      &:before {
        width: 100%;
      }
    }
  }



  .about {
    padding: 2rem 0;
    position: relative;

    .spna {
      position: absolute;
      z-index: -1;
      top: -50px;

      img {
        width: 50%;
      }
    }

    .about_wrp {
      display: flex;
      flex-direction: column-reverse;
      grid-template-columns: 1fr;
      align-items: center;

      .about_info {

        // background-image: url("../../images/scap_2.webp");
        // background-position: left;
        // background-repeat: no-repeat;
        h5 {
          font-size: responsive-font-size(1);
          color: $pry-color;
          margin-bottom: $spacing-unit;
          text-transform: uppercase;
          margin-top: 2rem;
        }

        h1 {
          font-size: responsive-font-size(3);
          font-weight: 800;
          text-transform: uppercase;
          letter-spacing: 1px;
        }

        p {
          margin-top: 2rem;
          font-size: 12px;
        }

        h4 {
          font-size: responsive-font-size(2);
          margin: 2rem 0;
          font-weight: 500;
        }

        .certy_wrp {

        max-width: 500px;
        width: 100%;
        padding: 0 ;
          

          img {
            width: 100%;
          }

        }
      }

      .img {
        align-items: flex-end;
        text-align: end;

        img {
          width: 100%;
        }

        .big_img {
          // width: 60%;
          margin-right: 5rem;
          display: none;
        }

        .small_img {
          position: relative;
          width: 100%;
          right: 0;
          top: 0;
          object-fit: cover;

        }
      }

    }


  }


  .deepartment {
    padding: 3rem 0;
    background: url("..//../images/snap_bg.webp");
    background-position: center;
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;

    .heading {
      text-align: center;
      margin-bottom: 5rem;

      h1 {
        font-size: responsive-font-size(1.5);
        font-weight: 700;
        text-transform: uppercase;
        padding: 2rem 0;
      }

      p {
        font-size: 12px;
      }
    }

    .slider {
      position: relative;
      margin-top: 1rem;

      .arrow {
        position: absolute;
        top: 0px;
        left: 90%;
        z-index: 1;
        cursor: pointer;
        background-color: $pry-color;
        padding: 0.4rem 0.6rem;
        border-radius: 50px;
        color: $white-color;


      }

      .next {
        font-size: 16px;
        font-weight: 600;
        left: 90%;

      }

      .prev {
        font-size: 16px;
        font-weight: 600;
        left: 80%;
        // background-color: red;

      }
    }

    .box {
      padding: 0.5rem;
      margin-top: 3rem;
    }

    .itme {
      padding: 1rem;
      border: 1px solid #00000020;
      border-radius: 5px;

      button {
        background: transparent !important;
        padding: 0;
        color: $pry-color;
        font-size: 14px;
        border: none;

        i {
          transition: all 0.6s ease-in-out;

        }

        &:hover i {
          padding-left: 1rem;

        }
      }

      img {
        width: 100%;
        object-fit: contain;
        margin-bottom: $spacing-unit;
      }

      h3 {
        font-size: responsive-font-size(1.1);

      }

      p {
        font-size: 12px;

        margin: 1.5rem 0;


      }


    }
  }

  .doctor {
    padding: 2rem 0;
    position: relative;

    .snap {
      position: absolute;
      z-index: -1;
      top: -60px;

      img {
        width: 50%;
      }
    }

    .slider {
      position: relative;
      margin-top: 5rem;

      .arrow {
        position: absolute;
        top: -50px;
        left: 90%;
        z-index: 1;
        cursor: pointer;
        background-color: $pry-color;
        padding: 0.4rem 0.6rem;
        border-radius: 50px;
        color: $white-color;


      }

      .next {
        font-size: 16px;
        font-weight: 600;
        left: 90%;

      }

      .prev {
        font-size: 16px;
        font-weight: 600;
        left: 80%;
        // background-color: red;

      }
    }

    .span {
      position: absolute;
      top: 80%;
      left: 0;
      right: 0;
      z-index: -1;

      img {
        width: 100%;
      }
    }

  }


}

@media screen and (max-width:500px) {
  .banner {
    background-image: url('../../images/banner_bg.webp');
    background-size: cover;
    background-position: center;
    height: 100%;
    position: relative;
    object-fit: contain;

  }


  .info {
    padding-top: 3rem;

    h1 {
      font-size: responsive-font-size(5);
    }

    p {
      font-size: 12px;
      margin-bottom: 0;
      opacity: 0.8;
    }
  }

  .em_book {
    position: relative;
    // bottom: -55%;
    left: 0;
    right: 0;
    top: 0;
    padding: 3rem 0;

    .em_wrp {
      // background: red;
      // @include flex-center(row, space-between, center)
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 2rem;
      gap: 2rem;

      .em_info {
        border-radius: 10px;
        padding: 2rem 2rem 0;
        box-shadow: none;
        background: linear-gradient(90deg, rgba(9, 9, 121, 0.913) 0%, rgba(9, 9, 121, 0.913) 100%), url("https://img.freepik.com/free-photo/attractive-female-doctor-standing-with-documents-hospital_1303-20688.jpg?t=st=1726698046~exp=1726701646~hmac=6697488243bfbb289ccc5955c8d3d301d76e072d52f519a115b609c4bdc567ba&w=826");
        background-size: contain;
        object-fit: contain;
        color: $white-color;

        .em_wrp {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;

          // align-items: flex-start;
          i {
            font-size: 48px;
            color: $pry-color;
          }

          p {
            font-size: 14px;
            margin: 0;
            margin-bottom: 0.5rem;
          }

          h3 {
            font-size: 22px;
            font-weight: 600;
            color: $pry-color;
          }
        }

        p {
          font-size: 14px;
          margin: 2rem 0;
        }

        button {
          padding: 0.5rem 1rem;
          font-size: 16px;

        }
      }

      .booking_box {
        background-color: #fff;
        background: url("../../images/em_bg.webp");
        border-radius: 10px;
        padding: 1rem;
        box-shadow: 0 0 20px #00000000;
        background-position: right;
        background-size: cover;
        background-repeat: no-repeat;
        border: 1px solid #aaa9a9;

        ul {
          @include flex-center(column)
        }

        // overflow: hidden;
        // .spna{
        //   position: absolute;
        //   right: 0%;
        //   height: 100%;
        //   bottom: 0;
        //   top: 50%;
        //   width: 20%;
        //   left: 80%;
        //   z-index: 1;
        //   img{
        //     width: 100%;
        //   }
        // }
        h1 {
          font-size: responsive-font-size(2.5);
          font-weight: 600;
          // margin: $spacing-unit 0;
          margin: 0;
          margin-bottom: 1rem;
          color: $pry-color;
        }

        form {


          .form__contorl {

            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            column-gap: 2rem;
            margin-bottom: 1rem;
            gap: 1em;

            .box {
              // width: 50%;

            }

          }
        }

        .btn {
          display: inline-block;
          padding: .75rem 2rem;
          border: none;
          color: #fff;
          text-transform: uppercase;
          font-size: responsive-font-size(1);
          transition: all .3s;
          position: relative;
          overflow: hidden;
          z-index: 1;

          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $pry-color;

            z-index: -2;
          }

          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0%;
            height: 100%;
            background-color: darken($pry-color, 15%);
            transition: all .3s;

            z-index: -1;
          }

          &:hover {
            color: #fff;

            &:before {
              width: 100%;
            }
          }
        }


      }

    }


  }

  .modalContainer {
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    box-shadow: 24;
    padding: 30px;
    border-radius: 10px;
    overflow-y: scroll;

    h4 {
      font-size: 20px;
      font-weight: 500;
    }
  }

  .modalTitle {
    color: #000000;
    font-size: 21px;
    font-weight: 600;
  }

  .modalSubtitle {
    font-weight: 400;
    font-size: 14px;
    color: #918E8E;
    padding-top: 10px;
  }

  .inputContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 2rem 0;
  }

  .inputField {
    border-radius: 10px;
  }

  .weekContainer {
    display: flex;
    justify-content: flex-start;
    margin: 20px 0;
    flex-wrap: wrap;
    column-gap: 1rem;
  }

  .dayLink {
    cursor: pointer;
    background-color: #D1EEFF69;
    padding: 0.5rem 1rem;
    border-radius: 50px;
    margin-bottom: 10px;
    text-align: center; // Optional for visual indication
    transition: background-color 0.3s;
    border: 1px solid $sec-color;
    color: $sec-color;

    &:hover {
      background-color: $pry-color; // Change this to your desired color
      color: $white-color;
    }
  }

  .selectedDay {
    background-color: $pry-color; // Change this to your desired color
    color: $white-color; // Change text color if needed
  }

  .timeSlots {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    column-gap: 2rem;
    gap: 1rem;
    flex-wrap: wrap;
    // justify-content: center;
  }

  .timeSlot {
    background-color: #D1EEFF69;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    text-align: center;
  }

  .selectedSlot {
    border: 2px solid $pry-color; // Highlight selected slot
  }

  .bookButton {
    margin-top: 20px;
    display: inline-block;
    padding: .75rem 2rem;
    border: none;
    color: #fff;
    text-transform: uppercase;
    font-size: responsive-font-size(1);
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $pry-color;

      z-index: -2;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: darken($pry-color, 15%);
      transition: all .3s;

      z-index: -1;
    }

    &:hover {
      color: #fff;

      &:before {
        width: 100%;
      }
    }
  }

  /* modalStyles.module.scss */
  .modal-container {
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    justify-content: center;
    box-shadow: 24px 24px 24px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    align-items: center;
  }

  .modal-image {
    max-width: 80%;
    height: 80%;
    padding: 10px;
  }

  .modal-title {
    color: #5b5b5b;
    font-size: 20px;
    font-weight: 600;
    padding: 10px;
  }

  .modal-text {
    font-weight: 400;
    font-size: 17px;
    color: #3a3a3a;
    padding: 10px;
    text-align: center;
  }




  .about {
    padding: 2rem 0;
    position: relative;

    .spna {
      position: absolute;
      z-index: -1;
      top: -50px;

      img {
        width: 50%;
      }
    }

    .about_wrp {
      display: flex;
      flex-direction: column-reverse;
      grid-template-columns: 1fr;
      align-items: center;

      .about_info {

        // background-image: url("../../images/scap_2.webp");
        // background-position: left;
        // background-repeat: no-repeat;
        h5 {
          font-size: responsive-font-size(1);
          color: $pry-color;
          margin-bottom: $spacing-unit;
          text-transform: uppercase;
          margin-top: 2rem;
        }

        h1 {
          font-size: responsive-font-size(3);
          font-weight: 800;
          text-transform: uppercase;
          letter-spacing: 1px;
        }

        p {
          margin-top: 2rem;
          font-size: 12px;
        }

        h4 {
          font-size: responsive-font-size(2);
          margin: 2rem 0;
          font-weight: 500;
        }

        .certy_wrp {

        max-width: 450px;
        padding: 0 ;
          

          img {
            width: 100%;
          }

        }
      }

      .img {
        align-items: flex-end;
        text-align: end;

        img {
          width: 100%;
        }

        .big_img {
          // width: 60%;
          margin-right: 5rem;
          display: none;
        }

        .small_img {
          position: relative;
          width: 100%;
          right: 0;
          top: 0;
          object-fit: cover;

        }
      }

    }


  }


  .deepartment {
    padding: 3rem 0;
    background: url("..//../images/snap_bg.webp");
    background-position: center;
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;

    .heading {
      text-align: center;
      margin-bottom: 5rem;

      h1 {
        font-size: responsive-font-size(1.5);
        font-weight: 700;
        text-transform: uppercase;
        padding: 2rem 0;
      }

      p {
        font-size: 12px;
      }
    }

    .slider {
      position: relative;
      margin-top: 1rem;

      .arrow {
        position: absolute;
        top: 0px;
        left: 90%;
        z-index: 1;
        cursor: pointer;
        background-color: $pry-color;
        padding: 0.4rem 0.6rem;
        border-radius: 50px;
        color: $white-color;


      }

      .next {
        font-size: 16px;
        font-weight: 600;
        left: 90%;

      }

      .prev {
        font-size: 16px;
        font-weight: 600;
        left: 80%;
        // background-color: red;

      }
    }

    .box {
      padding: 0.5rem;
      margin-top: 3rem;
    }

    .itme {
      padding: 1rem;
      border: 1px solid #00000020;
      height: 100%;
      border-radius: 5px;

      button {
        background: transparent !important;
        padding: 0;
        color: $pry-color;
        font-size: 14px;
        border: none;

        i {
          transition: all 0.6s ease-in-out;

        }

        &:hover i {
          padding-left: 1rem;

        }
      }

      img {
        width: 100%;
        object-fit: contain;
        margin-bottom: $spacing-unit;
      }

      h3 {
        font-size: responsive-font-size(1.1);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      p {
        font-size: 12px;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 1.5rem 0;


      }


    }
  }

  .doctor {
    padding: 2rem 0;
    position: relative;

    .snap {
      position: absolute;
      z-index: -1;
      top: -60px;

      img {
        width: 50%;
      }
    }

    .slider {
      position: relative;
      margin-top: 5rem;

      .arrow {
        position: absolute;
        top: -50px;
        left: 90%;
        z-index: 1;
        cursor: pointer;
        background-color: $pry-color;
        padding: 0.4rem 0.6rem;
        border-radius: 50px;
        color: $white-color;


      }

      .next {
        font-size: 16px;
        font-weight: 600;
        left: 90%;

      }

      .prev {
        font-size: 16px;
        font-weight: 600;
        left: 80%;
        // background-color: red;

      }
    }

    .span {
      position: absolute;
      top: 80%;
      left: 0;
      right: 0;
      z-index: -1;

      img {
        width: 100%;
        height: 150px;
      }
    }

  }


}

@media screen and (max-width:467px) {
  .banner {
    background-image: url('../../images/banner_bg.webp');
    background-size: cover;
    background-position: center;
    height: 50vh;
    position: relative;
    object-fit: contain;

  }


  .info {
    padding-top: 3rem;

    h1 {
      font-size: responsive-font-size(5);
    }

    p {
      font-size: 12px;
      margin-bottom: 0;
      opacity: 0.8;
    }
  }

  .em_book {
    position: relative;
    // bottom: -55%;
    left: 0;
    right: 0;
    top: 0;
    padding: 3rem 0;

    .em_wrp {
      // background: red;
      // @include flex-center(row, space-between, center)
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 2rem;
      gap: 2rem;

      .em_info {
        border-radius: 10px;
        padding: 2rem 2rem 0;
        box-shadow: none;
        background: linear-gradient(90deg, rgba(9, 9, 121, 0.913) 0%, rgba(9, 9, 121, 0.913) 100%), url("https://img.freepik.com/free-photo/attractive-female-doctor-standing-with-documents-hospital_1303-20688.jpg?t=st=1726698046~exp=1726701646~hmac=6697488243bfbb289ccc5955c8d3d301d76e072d52f519a115b609c4bdc567ba&w=826");
        background-size: contain;
        object-fit: contain;
        color: $white-color;

        .em_wrp {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;

          // align-items: flex-start;
          i {
            font-size: 48px;
            color: $pry-color;
          }

          p {
            font-size: 14px;
            margin: 0;
            margin-bottom: 0.5rem;
          }

          h3 {
            font-size: 22px;
            font-weight: 600;
            color: $pry-color;
          }
        }

        p {
          font-size: 14px;
          margin: 2rem 0;
        }

        button {
          padding: 0.5rem 1rem;
          font-size: 16px;

        }
      }

      .booking_box {
        background-color: #fff;
        background: url("../../images/em_bg.webp");
        border-radius: 10px;
        padding: 1rem;
        box-shadow: 0 0 20px #00000000;
        background-position: right;
        background-size: cover;
        background-repeat: no-repeat;
        border: 1px solid #aaa9a9;

        ul {
          @include flex-center(column)
        }

        // overflow: hidden;
        // .spna{
        //   position: absolute;
        //   right: 0%;
        //   height: 100%;
        //   bottom: 0;
        //   top: 50%;
        //   width: 20%;
        //   left: 80%;
        //   z-index: 1;
        //   img{
        //     width: 100%;
        //   }
        // }
        h1 {
          font-size: responsive-font-size(2.5);
          font-weight: 600;
          // margin: $spacing-unit 0;
          margin: 0;
          margin-bottom: 1rem;
          color: $pry-color;
        }

        form {


          .form__contorl {

            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            column-gap: 2rem;
            margin-bottom: 1rem;
            gap: 1em;

            .box {
              // width: 50%;

            }

          }
        }

        .btn {
          display: inline-block;
          padding: .75rem 2rem;
          border: none;
          color: #fff;
          text-transform: uppercase;
          font-size: responsive-font-size(1);
          transition: all .3s;
          position: relative;
          overflow: hidden;
          z-index: 1;

          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $pry-color;

            z-index: -2;
          }

          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0%;
            height: 100%;
            background-color: darken($pry-color, 15%);
            transition: all .3s;

            z-index: -1;
          }

          &:hover {
            color: #fff;

            &:before {
              width: 100%;
            }
          }
        }


      }

    }


  }

  .modalContainer {
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    box-shadow: 24;
    padding: 30px;
    border-radius: 10px;
    overflow-y: scroll;

    h4 {
      font-size: 20px;
      font-weight: 500;
    }
  }

  .modalTitle {
    color: #000000;
    font-size: 21px;
    font-weight: 600;
  }

  .modalSubtitle {
    font-weight: 400;
    font-size: 14px;
    color: #918E8E;
    padding-top: 10px;
  }

  .inputContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 2rem 0;
  }

  .inputField {
    border-radius: 10px;
  }

  .weekContainer {
    display: flex;
    justify-content: flex-start;
    margin: 20px 0;
    flex-wrap: wrap;
    column-gap: 1rem;
  }

  .dayLink {
    cursor: pointer;
    background-color: #D1EEFF69;
    padding: 0.5rem 1rem;
    border-radius: 50px;
    margin-bottom: 10px;
    text-align: center; // Optional for visual indication
    transition: background-color 0.3s;
    border: 1px solid $sec-color;
    color: $sec-color;

    &:hover {
      background-color: $pry-color; // Change this to your desired color
      color: $white-color;
    }
  }

  .selectedDay {
    background-color: $pry-color; // Change this to your desired color
    color: $white-color; // Change text color if needed
  }

  .timeSlots {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    column-gap: 2rem;
    gap: 1rem;
    flex-wrap: wrap;
    // justify-content: center;
  }

  .timeSlot {
    background-color: #D1EEFF69;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    text-align: center;
  }

  .selectedSlot {
    border: 2px solid $pry-color; // Highlight selected slot
  }

  .bookButton {
    margin-top: 20px;
    display: inline-block;
    padding: .75rem 2rem;
    border: none;
    color: #fff;
    text-transform: uppercase;
    font-size: responsive-font-size(1);
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $pry-color;

      z-index: -2;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: darken($pry-color, 15%);
      transition: all .3s;

      z-index: -1;
    }

    &:hover {
      color: #fff;

      &:before {
        width: 100%;
      }
    }
  }

  /* modalStyles.module.scss */
  .modal-container {
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    justify-content: center;
    box-shadow: 24px 24px 24px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    align-items: center;
  }

  .modal-image {
    max-width: 80%;
    height: 80%;
    padding: 10px;
  }

  .modal-title {
    color: #5b5b5b;
    font-size: 20px;
    font-weight: 600;
    padding: 10px;
  }

  .modal-text {
    font-weight: 400;
    font-size: 17px;
    color: #3a3a3a;
    padding: 10px;
    text-align: center;
  }




  .about {
    padding: 2rem 0;
    position: relative;

    .spna {
      position: absolute;
      z-index: -1;
      top: -50px;

      img {
        width: 50%;
      }
    }

    .about_wrp {
      display: flex;
      flex-direction: column-reverse;
      grid-template-columns: 1fr;
      align-items: center;

      .about_info {

        // background-image: url("../../images/scap_2.webp");
        // background-position: left;
        // background-repeat: no-repeat;
        h5 {
          font-size: responsive-font-size(1);
          color: $pry-color;
          margin-bottom: $spacing-unit;
          text-transform: uppercase;
          margin-top: 2rem;
        }

        h1 {
          font-size: responsive-font-size(3);
          font-weight: 800;
          text-transform: uppercase;
          letter-spacing: 1px;
        }

        p {
          margin-top: 2rem;
          font-size: 12px;
        }

        h4 {
          font-size: responsive-font-size(2);
          margin: 2rem 0;
          font-weight: 500;
        }

        .certy_wrp {
          max-width: 370px;
          padding: 0;

          img {
            width: 100%;
          }

        }
      }

      .img {
        align-items: flex-end;
        text-align: end;

        img {
          width: 100%;
        }

        .big_img {
          // width: 60%;
          margin-right: 5rem;
          display: none;
        }

        .small_img {
          position: relative;
          width: 100%;
          right: 0;
          top: 0;
          object-fit: cover;

        }
      }

    }


  }


  .deepartment {
    padding: 3rem 0;
    background: url("..//../images/snap_bg.webp");
    background-position: center;
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;

    .heading {
      text-align: center;
      margin-bottom: 5rem;

      h1 {
        font-size: responsive-font-size(1.5);
        font-weight: 700;
        text-transform: uppercase;
        padding: 2rem 0;
      }

      p {
        font-size: 12px;
      }
    }

    .slider {
      position: relative;
      margin-top: 1rem;

      .arrow {
        position: absolute;
        top: 0px;
        left: 90%;
        z-index: 1;
        cursor: pointer;
        background-color: $pry-color;
        padding: 0.4rem 0.6rem;
        border-radius: 50px;
        color: $white-color;


      }

      .next {
        font-size: 16px;
        font-weight: 600;
        left: 90%;

      }

      .prev {
        font-size: 16px;
        font-weight: 600;
        left: 80%;
        // background-color: red;

      }
    }

    .box {
      padding: 0.5rem;
      margin-top: 3rem;
    }

    .itme {
      padding: 1rem;
      border: 1px solid #00000020;
      border-radius: 5px;

      button {
        background: transparent !important;
        padding: 0;
        color: $pry-color;
        font-size: 14px;
        border: none;

        i {
          transition: all 0.6s ease-in-out;

        }

        &:hover i {
          padding-left: 1rem;

        }
      }

      img {
        width: 100%;
        object-fit: contain;
        margin-bottom: $spacing-unit;
      }

      h3 {
        font-size: responsive-font-size(1.1);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      p {
        font-size: 12px;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 1.5rem 0;


      }


    }
  }

  .doctor {
    padding: 2rem 0;
    position: relative;

    .snap {
      position: absolute;
      z-index: -1;
      top: -60px;

      img {
        width: 50%;
      }
    }

    .slider {
      position: relative;
      margin-top: 5rem;

      .arrow {
        position: absolute;
        top: -50px;
        left: 90%;
        z-index: 1;
        cursor: pointer;
        background-color: $pry-color;
        padding: 0.4rem 0.6rem;
        border-radius: 50px;
        color: $white-color;


      }

      .next {
        font-size: 16px;
        font-weight: 600;
        left: 90%;

      }

      .prev {
        font-size: 16px;
        font-weight: 600;
        left: 80%;
        // background-color: red;

      }
    }

    .span {
      position: absolute;
      top: 80%;
      left: 0;
      right: 0;
      z-index: -1;

      img {
        width: 100%;
        height: 150px;
      }
    }

  }


}

@media screen and (max-width:430px) {
  .banner {
    background-image: url('../../images/banner_bg.webp');
    background-size: cover;
    background-position: center;
    height: 40vh;
    position: relative;
    object-fit: contain;

  }

  .info {
    padding-top: 3rem;

    h1 {
      font-size: responsive-font-size(5);
    }

    p {
      font-size: 12px;
      margin-bottom: 0;
      opacity: 0.8;
    }
  }

  .em_book {
    position: relative;
    // bottom: -55%;
    left: 0;
    right: 0;
    top: 0;
    padding: 3rem 0;

    .em_wrp {
      // background: red;
      // @include flex-center(row, space-between, center)
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 2rem;
      gap: 2rem;

      .em_info {
        border-radius: 10px;
        padding: 2rem 2rem 0;
        box-shadow: none;
        background: linear-gradient(90deg, rgba(9, 9, 121, 0.913) 0%, rgba(9, 9, 121, 0.913) 100%), url("https://img.freepik.com/free-photo/attractive-female-doctor-standing-with-documents-hospital_1303-20688.jpg?t=st=1726698046~exp=1726701646~hmac=6697488243bfbb289ccc5955c8d3d301d76e072d52f519a115b609c4bdc567ba&w=826");
        background-size: contain;
        object-fit: contain;
        color: $white-color;

        .em_wrp {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;

          // align-items: flex-start;
          i {
            font-size: 48px;
            color: $pry-color;
          }

          p {
            font-size: 14px;
            margin: 0;
            margin-bottom: 0.5rem;
          }

          h3 {
            font-size: 22px;
            font-weight: 600;
            color: $pry-color;
          }
        }

        p {
          font-size: 14px;
          margin: 2rem 0;
        }

        button {
          padding: 0.5rem 1rem;
          font-size: 16px;

        }
      }

      .booking_box {
        background-color: #fff;
        background: url("../../images/em_bg.webp");
        border-radius: 10px;
        padding: 1rem;
        box-shadow: 0 0 20px #00000000;
        background-position: right;
        background-size: cover;
        background-repeat: no-repeat;
        border: 1px solid #aaa9a9;

        ul {
          @include flex-center(column)
        }

        // overflow: hidden;
        // .spna{
        //   position: absolute;
        //   right: 0%;
        //   height: 100%;
        //   bottom: 0;
        //   top: 50%;
        //   width: 20%;
        //   left: 80%;
        //   z-index: 1;
        //   img{
        //     width: 100%;
        //   }
        // }
        h1 {
          font-size: responsive-font-size(2.5);
          font-weight: 600;
          // margin: $spacing-unit 0;
          margin: 0;
          margin-bottom: 1rem;
          color: $pry-color;
        }

        form {


          .form__contorl {

            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            column-gap: 2rem;
            margin-bottom: 1rem;
            gap: 1em;

            .box {
              // width: 50%;

            }

          }
        }

        .btn {
          display: inline-block;
          padding: .75rem 2rem;
          border: none;
          color: #fff;
          text-transform: uppercase;
          font-size: responsive-font-size(1);
          transition: all .3s;
          position: relative;
          overflow: hidden;
          z-index: 1;

          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $pry-color;

            z-index: -2;
          }

          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0%;
            height: 100%;
            background-color: darken($pry-color, 15%);
            transition: all .3s;

            z-index: -1;
          }

          &:hover {
            color: #fff;

            &:before {
              width: 100%;
            }
          }
        }


      }

    }


  }

  .modalContainer {
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    box-shadow: 24;
    padding: 30px;
    border-radius: 10px;
    overflow-y: scroll;

    h4 {
      font-size: 20px;
      font-weight: 500;
    }
  }

  .modalTitle {
    color: #000000;
    font-size: 21px;
    font-weight: 600;
  }

  .modalSubtitle {
    font-weight: 400;
    font-size: 14px;
    color: #918E8E;
    padding-top: 10px;
  }

  .inputContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 2rem 0;
  }

  .inputField {
    border-radius: 10px;
  }

  .weekContainer {
    display: flex;
    justify-content: flex-start;
    margin: 20px 0;
    flex-wrap: wrap;
    column-gap: 1rem;
  }

  .dayLink {
    cursor: pointer;
    background-color: #D1EEFF69;
    padding: 0.5rem 1rem;
    border-radius: 50px;
    margin-bottom: 10px;
    text-align: center; // Optional for visual indication
    transition: background-color 0.3s;
    border: 1px solid $sec-color;
    color: $sec-color;

    &:hover {
      background-color: $pry-color; // Change this to your desired color
      color: $white-color;
    }
  }

  .selectedDay {
    background-color: $pry-color; // Change this to your desired color
    color: $white-color; // Change text color if needed
  }

  .timeSlots {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    column-gap: 2rem;
    gap: 1rem;
    flex-wrap: wrap;
    // justify-content: center;
  }

  .timeSlot {
    background-color: #D1EEFF69;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    text-align: center;
  }

  .selectedSlot {
    border: 2px solid $pry-color; // Highlight selected slot
  }

  .bookButton {
    margin-top: 20px;
    display: inline-block;
    padding: .75rem 2rem;
    border: none;
    color: #fff;
    text-transform: uppercase;
    font-size: responsive-font-size(1);
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $pry-color;

      z-index: -2;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: darken($pry-color, 15%);
      transition: all .3s;

      z-index: -1;
    }

    &:hover {
      color: #fff;

      &:before {
        width: 100%;
      }
    }
  }

  /* modalStyles.module.scss */
  .modal-container {
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    justify-content: center;
    box-shadow: 24px 24px 24px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    align-items: center;
  }

  .modal-image {
    max-width: 80%;
    height: 80%;
    padding: 10px;
  }

  .modal-title {
    color: #5b5b5b;
    font-size: 20px;
    font-weight: 600;
    padding: 10px;
  }

  .modal-text {
    font-weight: 400;
    font-size: 17px;
    color: #3a3a3a;
    padding: 10px;
    text-align: center;
  }




  .about {
    padding: 2rem 0;
    position: relative;

    .spna {
      position: absolute;
      z-index: -1;
      top: -50px;

      img {
        width: 50%;
      }
    }

    .about_wrp {
      display: flex;
      flex-direction: column-reverse;
      grid-template-columns: 1fr;
      align-items: center;

      .about_info {

        // background-image: url("../../images/scap_2.webp");
        // background-position: left;
        // background-repeat: no-repeat;
        h5 {
          font-size: responsive-font-size(1);
          color: $pry-color;
          margin-bottom: $spacing-unit;
          text-transform: uppercase;
          margin-top: 2rem;
        }

        h1 {
          font-size: responsive-font-size(3);
          font-weight: 800;
          text-transform: uppercase;
          letter-spacing: 1px;
        }

        p {
          margin-top: 2rem;
          font-size: 12px;
        }

        h4 {
          font-size: responsive-font-size(2);
          margin: 2rem 0;
          font-weight: 500;
        }

        .certy_wrp {
          max-width: 350px;
          padding: 0;

          img {
            width: 100%;
          }

        }
      }

      .img {
        align-items: flex-end;
        text-align: end;

        img {
          width: 100%;
        }

        .big_img {
          // width: 60%;
          margin-right: 5rem;
          display: none;
        }

        .small_img {
          position: relative;
          width: 100%;
          right: 0;
          top: 0;
          object-fit: cover;

        }
      }

    }


  }


  .deepartment {
    padding: 3rem 0;
    background: url("..//../images/snap_bg.webp");
    background-position: center;
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;

    .heading {
      text-align: center;
      margin-bottom: 5rem;

      h1 {
        font-size: responsive-font-size(1.5);
        font-weight: 700;
        text-transform: uppercase;
        padding: 2rem 0;
      }

      p {
        font-size: 12px;
      }
    }

    .slider {
      position: relative;
      margin-top: 1rem;

      .arrow {
        position: absolute;
        top: 0px;
        left: 90%;
        z-index: 1;
        cursor: pointer;
        background-color: $pry-color;
        padding: 0.4rem 0.6rem;
        border-radius: 50px;
        color: $white-color;


      }

      .next {
        font-size: 16px;
        font-weight: 600;
        left: 90%;

      }

      .prev {
        font-size: 16px;
        font-weight: 600;
        left: 80%;
        // background-color: red;

      }
    }

    .box {
      padding: 0.5rem;
      margin-top: 3rem;
    }

    .itme {
      padding: 1rem;
      border: 1px solid #00000020;
      border-radius: 5px;

      button {
        background: transparent !important;
        padding: 0;
        color: $pry-color;
        font-size: 14px;
        border: none;

        i {
          transition: all 0.6s ease-in-out;

        }

        &:hover i {
          padding-left: 1rem;

        }
      }

      img {
        width: 100%;
        object-fit: contain;
        margin-bottom: $spacing-unit;
      }

      h3 {
        font-size: responsive-font-size(1.1);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      p {
        font-size: 12px;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 1.5rem 0;


      }


    }
  }

  .doctor {
    padding: 2rem 0;
    position: relative;

    .snap {
      position: absolute;
      z-index: -1;
      top: -60px;

      img {
        width: 50%;
      }
    }

    .slider {
      position: relative;
      margin-top: 5rem;

      .arrow {
        position: absolute;
        top: -50px;
        left: 90%;
        z-index: 1;
        cursor: pointer;
        background-color: $pry-color;
        padding: 0.4rem 0.6rem;
        border-radius: 50px;
        color: $white-color;


      }

      .next {
        font-size: 16px;
        font-weight: 600;
        left: 90%;

      }

      .prev {
        font-size: 16px;
        font-weight: 600;
        left: 80%;
        // background-color: red;

      }
    }

    .span {
      position: absolute;
      top: 80%;
      left: 0;
      right: 0;
      z-index: -1;

      img {
        width: 100%;
        height: 150px;
      }
    }

  }


}

@media screen and (max-width:376px) {
  .banner {
    background-image: url('../../images/banner_bg.webp');
    background-size: cover;
    background-position: center;
    height: 50vh;
    position: relative;
    object-fit: contain;

  }
}