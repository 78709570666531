@import "../Configration/Veriabales.scss";
@import "../Configration/Mixin.scss";

.card {
    margin: 0 1rem;
    border-radius: 12px;
    overflow: hidden;
    background-color: #d1eeff71;
    position: relative;
    padding: 1rem;
  }
  
  .image_container {
    position: relative;
    width: 100%;
  }
  
  .card_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .book_button {
    position: absolute;
    left: 50%;
    // right: 0;
    bottom: 0;
    transform: translate(-50%, -50%);
    background-color: #2196f3; // Blue background for button
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 50px;
    font-size: 12px;
    cursor: pointer;
    opacity: 0; // Initially hidden
    transition: opacity 0.3s ease;
    width: 90%;
    // margin: 0 12px;
   
  }
  
  .image_container:hover .book_button {
    opacity: 1; // Show button on hover
  }
  
  .card_body {
    padding: 15px 0 0;
    border-radius: 0 0 12px 12px;
    color: #fff;
  }
  
  .card_name {
    font-size: responsive-font-size(1);
    font-weight: 600;
    margin-bottom: 5px;
    color: black;
  }
  
  .card_description {
    font-size: responsive-font-size(0.5);
    color: $white-color;
    margin-bottom: 15px;
    width: 100%;
    white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }
  
  .social_icons {
    display: flex;
    justify-content: space-between;
    gap: 15px;
  
    i {
      font-size: 16px;
      color: #fff;
      transition: color 0.3s ease;
      margin: 0 0.5rem;
  
      &:hover {
        color: #e3f2fd; // Hover effect to match the light blue
      }
    }
  }
  
  @media screen and (max-width:1200px) {
    .card {
      margin: 0 1rem;
      border-radius: 12px;
      overflow: hidden;
      background-color: #d1eeff71;
      position: relative;
      padding: 1rem;
    }
    
    .image_container {
      position: relative;
      width: 100%;
    }
    
    .card_image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
    
    .book_button {
      position: absolute;
      left: 50%;
      // right: 0;
      bottom: -10%;
      transform: translate(-50%, -180%);
      background-color: #2196f3; // Blue background for button
      color: white;
      padding: 10px 20px;
      border: none;
      border-radius: 50px;
      font-size: 12px;
      cursor: pointer;
      opacity: 1; // Initially hidden
      transition: opacity 0.3s ease;
      width: 90%;
      // margin: 0 12px;
     
    }
    
    .image_container:hover .book_button {
      opacity: 1; // Show button on hover
    }
    
    .card_body {
      padding: 15px 0 0;
      border-radius: 0 0 12px 12px;
      color: #fff;
    }
    
    .card_name {
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 5px;
      color: black;
     
    }
    
    .card_description {
      font-size: 12px;
      color: $white-color;
      margin-bottom: 15px;
    }
    
    .social_icons {
      display: flex;
      justify-content: space-between;
      gap: 5px;
    
      i {
        font-size: 12px;
        color: #fff;
        transition: color 0.3s ease;
        margin: 0 0.2rem;
    
        &:hover {
          color: #e3f2fd; // Hover effect to match the light blue
        }
      }
    }
    
  }


  @media screen and (max-width:767px) {
    .card {
      margin: 0 1rem;
      border-radius: 12px;
      overflow: hidden;
      background-color: #d1eeff71;
      position: relative;
      padding: 1rem;
    }
    
    .image_container {
      position: relative;
      width: 100%;
    }
    
    .card_image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
    
    .book_button {
      position: absolute;
      left: 50%;
      // right: 0;
      bottom: -10%;
      transform: translate(-50%, -180%);
      background-color: #2196f3; // Blue background for button
      color: white;
      padding: 10px 20px;
      border: none;
      border-radius: 50px;
      font-size: 12px;
      cursor: pointer;
      opacity: 1; // Initially hidden
      transition: opacity 0.3s ease;
      width: 90%;
      // margin: 0 12px;
     
    }
    
    .image_container:hover .book_button {
      opacity: 1; // Show button on hover
    }
    
    .card_body {
      padding: 15px 0 0;
      border-radius: 0 0 12px 12px;
      color: #fff;
    }
    
    .card_name {
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 5px;
      color: black;
     
    }
    
    .card_description {
      font-size: 12px;
      color: $white-color;
      margin-bottom: 15px;
    }
    
    .social_icons {
      display: flex;
      justify-content: space-between;
      gap: 5px;
    
      i {
        font-size: 12px;
        color: #fff;
        transition: color 0.3s ease;
        margin: 0 0.2rem;
    
        &:hover {
          color: #e3f2fd; // Hover effect to match the light blue
        }
      }
    }
    
  }

  @media screen and (max-width:500px) {
    .card {
      margin: 0 3rem;
      border-radius: 12px;
      overflow: hidden;
      background-color: #d1eeff71;
      position: relative;
      padding: 1rem;
    }
    
    .image_container {
      position: relative;
      width: 100%;
    }
    
    .card_image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
    
    .book_button {
      position: absolute;
      left: 50%;
      // right: 0;
      bottom: -10%;
      transform: translate(-50%, -180%);
      background-color: #2196f3; // Blue background for button
      color: white;
      padding: 10px 20px;
      border: none;
      border-radius: 50px;
      font-size: 12px;
      cursor: pointer;
      opacity: 1; // Initially hidden
      transition: opacity 0.3s ease;
      width: 90%;
      // margin: 0 12px;
     
    }
    
    .image_container:hover .book_button {
      opacity: 1; // Show button on hover
    }
    
    .card_body {
      padding: 15px 0 0;
      border-radius: 0 0 12px 12px;
      color: #fff;
    }
    
    .card_name {
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 5px;
      color: black;
     
    }
    
    .card_description {
      font-size: 12px;
      color: $white-color;
      margin-bottom: 15px;
    }
    
    .social_icons {
      display: flex;
      justify-content: space-between;
      gap: 5px;
    
      i {
        font-size: 12px;
        color: #fff;
        transition: color 0.3s ease;
        margin: 0 0.2rem;
    
        &:hover {
          color: #e3f2fd; // Hover effect to match the light blue
        }
      }
    }
    
  }