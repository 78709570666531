.steps_section {
    // padding: 50px 20px;
    padding-top: 10rem;
    padding-bottom: 5rem;
    text-align: center;
    background: url("../../images/map_bg.webp");
    background-position: center;
  
    .choose_doctors {
      color: #00aaff;
      font-size: 1rem;
      margin-bottom: 5px;
    }
  
    .section_title {
      font-size: 2.5rem;
      font-weight: bold;
      margin-bottom: 40px;
      color: #333;
    }
  
    .steps_container {
      display: grid;
      grid-template-columns: repeat(4,1fr);
      justify-content: center;
      gap: 40px;
      flex-wrap: wrap;
      margin-top: 5rem;
    }
  }



  @media screen and (max-width:1200px) {
    .steps_section {
      // padding: 50px 20px;
      padding-top: 3rem;
      padding-bottom: 5rem;
      text-align: center;
      background: url("../../images/map_bg.webp");
      background-position: center;
    
      .choose_doctors {
        color: #00aaff;
        font-size: 1rem;
        margin-bottom: 5px;
      }
    
      .section_title {
        font-size: 2.5rem;
        font-weight: bold;
        margin-bottom: 40px;
        color: #333;
      }
    
      .steps_container {
        display: grid;
        grid-template-columns: repeat(4,1fr);
        justify-content: center;
        gap: 5px;
        margin-top: 2rem;
      }
    }
  }

  @media screen and (max-width:993px) {
    .steps_section {
      // padding: 50px 20px;
      padding-top: 3rem;
      padding-bottom: 5rem;
      text-align: center;
      background: url("../../images/map_bg.webp");
      background-position: center;
    
      .choose_doctors {
        color: #00aaff;
        font-size: 1rem;
        margin-bottom: 5px;
      }
    
      .section_title {
        font-size: 2.5rem;
        font-weight: bold;
        margin-bottom: 40px;
        color: #333;
      }
    
      .steps_container {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        justify-content: center;
        gap: 20px;
        flex-wrap: wrap;
        margin-top: 2rem;
      }
    }
  }


  @media screen and (max-width:376px) {
    .steps_section {
      // padding: 50px 20px;
      padding-top: 3rem;
      padding-bottom: 5rem;
      text-align: center;
      background: url("../../images/map_bg.webp");
      background-position: center;
    
      .choose_doctors {
        color: #00aaff;
        font-size: 1rem;
        margin-bottom: 5px;
      }
    
      .section_title {
        font-size: 2.5rem;
        font-weight: bold;
        margin-bottom: 40px;
        color: #333;
      }
    
      .steps_container {
        display: grid;
        grid-template-columns: repeat(1,1fr);
        justify-content: center;
        gap: 20px;
        flex-wrap: wrap;
        margin-top: 2rem;
      }
    }
  }
  