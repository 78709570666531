@import "../Configration/Veriabales.scss";
.step_card {
    text-align: center;
    padding: 20px;
    position: relative;
    // background-color: #fff;
    border-radius: 10px;
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
    .circle {
      position: relative;
      width: 200px;
      height: 200px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .step_image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%; /* Makes the image circular */
      border: 5px solid $pry-color; /* Optional border for styling */
    }
  
    .badge {
      position: absolute;
      top: -10px;
      right: -10px;
      width: 70px;
      height: 70px;
      background-color: $pry-color; /* Badge background color */
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%; /* Makes the badge circular */
      font-size: 22px;
      font-weight: bold;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); /* Optional shadow */
    }
  
    .step_title {
      font-size: 22px;
      font-weight: 600;
      margin-top: 15px;
    }
  
    .step_description {
      font-size: 14px;
      color: #777;
      margin-top: 10px;
    }
  }


  @media screen and (max-width:1200px) {
    .step_card {
      text-align: center;
      padding: 20px;
      position: relative;
      // background-color: #fff;
      border-radius: 10px;
      // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    
      .circle {
        position: relative;
        width: 200px !important;
        height: 200px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    
      .step_image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%; /* Makes the image circular */
        border: 5px solid $pry-color; /* Optional border for styling */
      }
    
      .badge {
        position: absolute;
        top: -5px;
        right: -5px;
        width: 40px;
        height: 40px;
        background-color: $pry-color; /* Badge background color */
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%; /* Makes the badge circular */
        font-size: 16px;
        font-weight: bold;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); /* Optional shadow */
      }
    
      .step_title {
        font-size: 16px;
        font-weight: 600;
        margin-top: 15px;
      }
    
      .step_description {
        font-size: 12px;
        color: #777;
        margin-top: 10px;
      }
    }
  }
    
  @media screen and (max-width:767px) {
  .step_card {
    text-align: center;
    padding: 20px;
    position: relative;
    // background-color: #fff;
    border-radius: 10px;
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
    .circle {
      position: relative;
      width: 150px !important;
      height: 150px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .step_image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%; /* Makes the image circular */
      border: 5px solid $pry-color; /* Optional border for styling */
    }
  
    .badge {
      position: absolute;
      top: -5px;
      right: -5px;
      width: 40px;
      height: 40px;
      background-color: $pry-color; /* Badge background color */
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%; /* Makes the badge circular */
      font-size: 16px;
      font-weight: bold;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); /* Optional shadow */
    }
  
    .step_title {
      font-size: 16px;
      font-weight: 600;
      margin-top: 15px;
    }
  
    .step_description {
      font-size: 12px;
      color: #777;
      margin-top: 10px;
    }
  }
}
  