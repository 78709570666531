@import "../Configration/Mixin.scss";
@import "../Configration/Veriabales.scss";


.heading {
    text-align: center;
    margin-bottom: 3rem;

    h5 {
        font-size: responsive-font-size(1);
        color: $pry-color;
        margin-bottom: $spacing-unit;
        text-transform: uppercase;
      }

      h1 {
        font-size: responsive-font-size(3);
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      p {
        margin-top: 2rem;
        
      }
      @media screen and (max-width:767px) {
        p {
          margin-top: 1rem;
          font-size: 12px;
          opacity: 0.8;
        }
      }

      h4 {
        font-size: responsive-font-size(1.5);
        margin: 2rem 0;
        font-weight: 500;
      }
  }