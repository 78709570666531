@import "../Configration/Mixin.scss";
@import "../Configration/Veriabales.scss";

.banner{
  background: linear-gradient(90deg, rgba(8, 24, 57, 0.712) 0%, rgba(8,24,57,0.258) 100%), url("../../../../public/images/bg.webp");
  padding: 5rem 0;
  // text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  h1{
    color: $white-color;
    margin-bottom: 1rem;
    font-size: 48px;
  }
  span{
    margin-top: 1rem;
    color: #b3b3b3;
    a{
      color: #fff;
      &:hover{
        color: rgb(60, 185, 252);
      }
    }
      
  }
  

}
.department_layout {
  display: grid;
  grid-template-columns: 1fr 2.5fr; /* Use flexbox for layout */
  height: calc(100vh - 6rem); /* Full height minus header/footer */
  margin: 3rem 0;
  column-gap: 2rem; /* Adjust margin as needed */
  
  .department_list {
    flex: 0 0 400px; /* Fixed width for the left sidebar */
    position: sticky; /* Stick the left sidebar */
    top: 0; /* Stick to the top */
    height: 100%; /* Full height of the parent */
    overflow-y: auto; /* Scroll if content overflows */
    // background-color: #f6f6f6; /* Background color for contrast */
    // padding: 1rem; /* Padding for aesthetics */
    // box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Optional shadow for visual separation */

    ul {
      @include flex-center(column, flex-start, flex-start);
      margin: 0;
      padding: 0; /* Remove default padding */
      list-style: none; /* Remove default list styles */
    }
    
    li {
      width: 100%; /* Ensure li takes full width */
      padding: 1.5rem 1rem;
      font-size: 16px;
      cursor: pointer;
      background-color: #f6f6f6; /* Background for list items */

      &:hover {
        background-color: #d1eeff93; /* Hover background */
        border-right: 5px solid $pry-color; /* Right border on hover */
        color: $pry-color; /* Change text color on hover */
        transition: all 0.3s ease-in-out; /* Smooth transition */
      }

      &.active_department {
        background-color: #d1eeff93; /* Active background */
        border-right: 5px solid $pry-color; /* Active right border */
        color: $pry-color; /* Active text color */
      }
    }
  }

  .department_details {
    flex: 1; /* Allow the right side to take the remaining space */
    padding: 0 1rem 1rem; /* Padding for aesthetics */
    overflow-y: auto; /* Allow scrolling if content overflows */
    &::-webkit-scrollbar {
      display: none; /* Hide scrollbar */
    }
    .img {
      img {
        width: 100%; /* Full width */
        height: 400px; /* Maintain aspect ratio */
        object-fit: cover; /* Cover for image */
        vertical-align: top; 
      }
    }
    
    h2 {
      margin-bottom: 1rem;
      margin-top: 1rem;
      font-size: 32px; /* Adjust font size */
    }

    p {
      font-size: 14px; /* Base font size */
      opacity: 0.8; /* Slight opacity */
      letter-spacing: 0.25px;
      padding-bottom: 1rem; /* Letter spacing */
    }

    h6{
      font-weight: 500;
      font-size: 1.2rem;
      padding-top: 1rem;
    }

    ul{
      padding-left: 1rem;
      padding-top: 1rem;
      li{
        span{
          font-weight: 600;
        }
      }
    }

    .doctor_list {
      display: grid;
      grid-template-columns: repeat(3, 1fr); /* Two columns for doctor list */
      margin-top: 2rem;
     gap: 2rem;

      .card {
        // margin: 0 1rem;
        border-radius: 12px;
        overflow: hidden;
        background-color: #d1eeff71; /* Card background */
        position: relative;
        padding: 1rem;
        height: 100%; /* Fixed height for cards */
        width: 100%; /* Full width for cards */
        
        .image_container {
          position: relative;
          
          img {
            width: 100%;
            height: 100%; /* Fixed height for images */
            object-fit: cover; /* Cover for image */
            border-radius: 10px; /* Rounded corners */
          }

          .book_button {
            position: absolute;
            left: 50%;
            bottom:0px; /* Button position */
            transform: translate(-50%, -50%); /* Center button */
            background-color: #2196f3; /* Button background */
            color: white; /* Button text color */
            padding: 10px 20px;
            border: none;
            border-radius: 50px; /* Rounded button */
            font-size: 12px; /* Button font size */
            cursor: pointer; /* Pointer on hover */
            opacity: 0; /* Initially hidden */
            transition: opacity 0.3s ease; /* Smooth transition */
            width: 90%; /* Width for button */
          }
        }

        .image_container:hover .book_button {
          opacity: 1; /* Show button on hover */
        }

        .card_body {
          border-radius: 0 0 12px 12px; /* Rounded bottom corners */
          color: #fff;
          
          h3 {
            font-size: responsive-font-size(1); /* Card title size */
            font-weight: 600;
            margin-bottom: 5px;
            color: black; /* Title color */
          }

          p {
            font-size: responsive-font-size(0.6); /* Description font size */
            color: $pry-color; /* Description color */
            margin-top: 0; /* No margin */
          }
        }
      }
    }
  }
}

.social_icons {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-top: 1rem;

  i {
    font-size: 20px;
    color: #5d5d5d;
    transition: color 0.3s ease;
    margin: 0 0.5rem;
    cursor: pointer;
    transition: all 0.4s ease-in-out;

    &:hover {
      color: $pry-color;
      transform: scale(1.2); /* Scale effect on hover */
    }
  }
}
.department_dropdown {
  display: none;

  

  select {
    width: 100%;
    padding: 10px;
    font-size: 16px;
  }
}



@media screen and (max-width:1200px) {
  .department_layout {
    display: grid;
    grid-template-columns: 1fr 2.5fr; /* Use flexbox for layout */
    height: calc(100vh - 6rem); /* Full height minus header/footer */
    margin: 3rem 0;
    column-gap: 2rem; /* Adjust margin as needed */
    
    .department_list {
      flex: 0 0 400px; /* Fixed width for the left sidebar */
      position: sticky; /* Stick the left sidebar */
      top: 0; /* Stick to the top */
      height: 100%; /* Full height of the parent */
      overflow-y: auto; /* Scroll if content overflows */
      // background-color: #f6f6f6; /* Background color for contrast */
      // padding: 1rem; /* Padding for aesthetics */
      // box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Optional shadow for visual separation */
  
      ul {
        @include flex-center(column, flex-start, flex-start);
        margin: 0;
        padding: 0; /* Remove default padding */
        list-style: none; /* Remove default list styles */
      }
      
      li {
        width: 100%; /* Ensure li takes full width */
        padding: 1.5rem 1rem;
        font-size: 12px;
        cursor: pointer;
        background-color: #f6f6f6; /* Background for list items */
  
        &:hover {
          background-color: #d1eeff93; /* Hover background */
          border-right: 5px solid $pry-color; /* Right border on hover */
          color: $pry-color; /* Change text color on hover */
          transition: all 0.3s ease-in-out; /* Smooth transition */
        }
  
        &.active_department {
          background-color: #d1eeff93; /* Active background */
          border-right: 5px solid $pry-color; /* Active right border */
          color: $pry-color; /* Active text color */
        }
      }
    }
  
    .department_details {
      flex: 1; /* Allow the right side to take the remaining space */
      padding: 0 1rem 1rem; /* Padding for aesthetics */
      overflow-y: auto; /* Allow scrolling if content overflows */
      &::-webkit-scrollbar {
        display: none; /* Hide scrollbar */
      }
      .img {
        img {
          width: 100%; /* Full width */
          height: 400px; /* Maintain aspect ratio */
          object-fit: cover; /* Cover for image */
          vertical-align: top; 
        }
      }
      
      h2 {
        margin-bottom: 1rem;
        margin-top: 1rem;
        font-size: resposive-font-size(1); /* Adjust font size */
      }
  
      p {
        font-size: 14px; /* Base font size */
        opacity: 0.8; /* Slight opacity */
        letter-spacing: 0.25px;
        padding-bottom: 1rem; /* Letter spacing */
      }
  
      .doctor_list {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two columns for doctor list */
        margin-top: 2rem;
        column-gap: 2rem;
  
        .card {
          // margin: 0 1rem;
          border-radius: 12px;
          overflow: hidden;
          background-color: #d1eeff71; /* Card background */
          position: relative;
          padding: 1rem;
          height: 100%; /* Fixed height for cards */
          width: 100%; /* Full width for cards */
          
          .image_container {
            position: relative;
            
            img {
              width: 100%;
              height: 100%; /* Fixed height for images */
              object-fit: cover; /* Cover for image */
              border-radius: 10px; /* Rounded corners */
            }
  
            .book_button {
              position: absolute;
              left: 50%;
              bottom:0px; /* Button position */
              transform: translate(-50%, -50%); /* Center button */
              background-color: #2196f3; /* Button background */
              color: white; /* Button text color */
              padding: 10px 20px;
              border: none;
              border-radius: 50px; /* Rounded button */
              font-size: 12px; /* Button font size */
              cursor: pointer; /* Pointer on hover */
              opacity: 0; /* Initially hidden */
              transition: opacity 0.3s ease; /* Smooth transition */
              width: 90%; /* Width for button */
            }
          }
  
          .image_container:hover .book_button {
            opacity: 1; /* Show button on hover */
          }
  
          .card_body {
            border-radius: 0 0 12px 12px; /* Rounded bottom corners */
            color: #fff;
            
            h3 {
              font-size: 14px; /* Card title size */
              font-weight: 600;
              margin-bottom: 5px;
              color: black; /* Title color */
            }
  
            p {
              font-size: 14px; /* Description font size */
              color: $pry-color; /* Description color */
              margin-top: 0; /* No margin */
            }
          }
        }
      }
    }
  }
  
  .social_icons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 1rem;
  
    i {
      font-size: 16px;
      color: #5d5d5d;
      transition: color 0.3s ease;
      margin: 0 0.5rem;
      cursor: pointer;
      transition: all 0.4s ease-in-out;
      
  
      &:hover {
        color: $pry-color;
        transform: scale(1.2); /* Scale effect on hover */
      }
    }
  }
  .department_dropdown {
    display: none;
  
    
  
    select {
      width: 100%;
      padding: 10px;
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 993px) {
  .department_list{
    display: none;
  }
  .department_dropdown{
    display: block; // Show the dropdown on mobile
  margin-bottom: 15px;
  }

  .department_layout {
    display: grid;
    grid-template-columns: 1fr ; /* Use flexbox for layout */
    height: calc(100vh - 6rem); /* Full height minus header/footer */
    margin: 3rem 0;
    column-gap: 2rem; /* Adjust margin as needed */
  
  
    .department_details {
      flex: 1; /* Allow the right side to take the remaining space */
      padding: 0 1rem 3rem; /* Padding for aesthetics */
      overflow-y: auto; /* Allow scrolling if content overflows */
      &::-webkit-scrollbar {
        display: none; /* Hide scrollbar */
      }
      .img {
        img {
          width: 100%; /* Full width */
          height: 400px; /* Maintain aspect ratio */
          object-fit: cover; /* Cover for image */
          vertical-align: top; 
        }
      }
      
      h2 {
        margin-bottom: 1rem;
        margin-top: 1rem;
        font-size: 32px; /* Adjust font size */
      }
  
      p {
        font-size: 14px; /* Base font size */
        opacity: 0.8; /* Slight opacity */
        letter-spacing: 0.25px;
        padding-bottom: 1rem; /* Letter spacing */
      }
  
      .doctor_list {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two columns for doctor list */
        margin-top: 2rem;
        column-gap: 2rem;
  
        
      }
    }
  }
  .card {
    // margin: 0 1rem;
    border-radius: 12px;
    overflow: hidden;
    background-color: #d1eeff71; /* Card background */
    position: relative;
    padding: 1rem;
    height: 100%; /* Fixed height for cards */
    width: 100%; /* Full width for cards */
    
    .image_container {
      position: relative;
      
      img {
        width: 100%;
        height: 100%; /* Fixed height for images */
        object-fit: cover; /* Cover for image */
        border-radius: 10px; /* Rounded corners */
      }

      .book_button {
        position: absolute;
        left: 50%;
        bottom:0px; /* Button position */
        transform: translate(-50%, -50%); /* Center button */
        background-color: #2196f3; /* Button background */
        color: white; /* Button text color */
        padding: 10px 20px;
        border: none;
        border-radius: 50px; /* Rounded button */
        font-size: 12px; /* Button font size */
        cursor: pointer; /* Pointer on hover */
        opacity: 1; /* Initially hidden */
        transition: opacity 0.3s ease; /* Smooth transition */
        width: 90%; /* Width for button */
      }
    }

    .image_container:hover .book_button {
      opacity: 1; /* Show button on hover */
    }

    .card_body {
      border-radius: 0 0 12px 12px; /* Rounded bottom corners */
      color: #fff;
      
      h3 {
        font-size: 14px; /* Card title size */
        font-weight: 600;
        margin-bottom: 5px;
        color: black; /* Title color */
      }

      p {
        font-size: 12px; /* Description font size */
        color: $pry-color; /* Description color */
        margin-top: 0; /* No margin */
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .department_list{
    display: none;
  }

  .department_dropdown{
    display: block; // Show the dropdown on mobile
    margin-bottom: 15px;
  }

  .department_layout {
    display: grid;
    grid-template-columns: 1fr ; /* Use flexbox for layout */
    height: calc(100vh - 6rem); /* Full height minus header/footer */
    margin: 3rem 0;
    column-gap: 2rem; /* Adjust margin as needed */
    .department_details {
      flex: 1; /* Allow the right side to take the remaining space */
      padding: 0 1rem 3rem; /* Padding for aesthetics */
      overflow-y: auto; /* Allow scrolling if content overflows */
      &::-webkit-scrollbar {
        display: none; /* Hide scrollbar */
      }
      .img {
        img {
          width: 100%; /* Full width */
          height: 100%; /* Maintain aspect ratio */
          object-fit: cover; /* Cover for image */
          vertical-align: top; 
        }
      }
      
      h2 {
        margin-bottom: 1rem;
        margin-top: 1rem;
        font-size: 32px; /* Adjust font size */
      }
  
      p {
        font-size: 14px; /* Base font size */
        opacity: 0.8; /* Slight opacity */
        letter-spacing: 0.25px;
        padding-bottom: 1rem; /* Letter spacing */
      }
  
      .doctor_list {
        display: grid;
        grid-template-columns: repeat(1, 1fr); /* Two columns for doctor list */
        margin-top: 2rem;
        margin: 2r;
        column-gap: 2rem;
  
        .card {
          // margin: 0 1rem;
          border-radius: 12px;
          overflow: hidden;
          background-color: #d1eeff71; /* Card background */
          position: relative;
          padding: 1rem;
          height: 100%; /* Fixed height for cards */
          width: 100%; /* Full width for cards */
          
          .image_container {
            position: relative;
            
            img {
              width: 100%;
              height: 100%; /* Fixed height for images */
              object-fit: cover; /* Cover for image */
              border-radius: 10px; /* Rounded corners */
            }
      
            .book_button {
              position: absolute;
              left: 50%;
              bottom:0px; /* Button position */
              transform: translate(-50%, -50%); /* Center button */
              background-color: #2196f3; /* Button background */
              color: white; /* Button text color */
              padding: 10px 20px;
              border: none;
              border-radius: 50px; /* Rounded button */
              font-size: 12px; /* Button font size */
              cursor: pointer; /* Pointer on hover */
              opacity: 1; /* Initially hidden */
              transition: opacity 0.3s ease; /* Smooth transition */
              width: 90%; /* Width for button */
            }
          }
      
          .image_container:hover .book_button {
            opacity: 1; /* Show button on hover */
          }
      
          .card_body {
            border-radius: 0 0 12px 12px; /* Rounded bottom corners */
            color: #fff;
            
            h3 {
              font-size: 14px; /* Card title size */
              font-weight: 600;
              margin-bottom: 5px;
              color: black; /* Title color */
            }
      
            p {
              font-size: 12px; /* Description font size */
              color: $pry-color; /* Description color */
              margin-top: 0; /* No margin */
            }
          }
        }
      }
    }
  }

  
}

@media screen and (max-width: 400px) {
  .banner{

    h1{
      color: $white-color;
      margin-bottom: 1rem;
      font-size: 36px;
    }
    span{
      margin-top: 1rem;
      color: #d3d3d3;
      a{
        color: #fff;
        &:hover{
          color: rgb(60, 185, 252);
        }
      }
        
    }
    
  
  }
  .department_list{
    display: none;
  }
  .department_dropdown{
    display: block; // Show the dropdown on mobile
    margin-bottom: 15px;
    select{
      border: none;
      border: 1px solid #000;
      border-radius: 5px;

    }
  }

  .department_layout {
    display: grid;
    grid-template-columns: 1fr ; /* Use flexbox for layout */
    height: calc(100vh - 6rem); /* Full height minus header/footer */
    margin: 3rem 0;
    column-gap: 2rem; /* Adjust margin as needed */
  
  
    .department_details {
      flex: 1; /* Allow the right side to take the remaining space */
      padding: 0 0 1rem; /* Padding for aesthetics */
      overflow-y: auto; /* Allow scrolling if content overflows */
      &::-webkit-scrollbar {
        display: none; /* Hide scrollbar */
      }
      .img {
        img {
          width: 100%; /* Full width */
          height: 400px; /* Maintain aspect ratio */
          object-fit: cover; /* Cover for image */
          vertical-align: top; 
        }
      }
      
      h2 {
        margin-bottom: 1rem;
        margin-top: 1rem;
        font-size: 18px; /* Adjust font size */
      }
  
      p {
        font-size: 12px; /* Base font size */
        opacity: 0.8; /* Slight opacity */
        letter-spacing: 0.25px;
        padding-bottom: 1rem; /* Letter spacing */
      }
  
      .doctor_list {
        display: grid;
        grid-template-columns: repeat(1, 1fr); /* Two columns for doctor list */
        margin-top: 2rem;
        column-gap: 2rem;
  
        .card {
          // margin: 0 1rem;
          border-radius: 12px;
          overflow: hidden;
          background-color: #d1eeff71; /* Card background */
          position: relative;
          padding: 1rem;
          height: 100%; /* Fixed height for cards */
          width: 100%; /* Full width for cards */
          
          .image_container {
            position: relative;
            
            img {
              width: 100%;
              height: 100%; /* Fixed height for images */
              object-fit: cover; /* Cover for image */
              border-radius: 10px; /* Rounded corners */
            }
  
            .book_button {
              position: absolute;
              left: 50%;
              bottom:0px; /* Button position */
              transform: translate(-50%, -50%); /* Center button */
              background-color: #2196f3; /* Button background */
              color: white; /* Button text color */
              padding: 10px 20px;
              border: none;
              border-radius: 50px; /* Rounded button */
              font-size: 12px; /* Button font size */
              cursor: pointer; /* Pointer on hover */
              opacity: 1; /* Initially hidden */
              transition: opacity 0.3s ease; /* Smooth transition */
              width: 90%; /* Width for button */
            }
          }
  
          .image_container:hover .book_button {
            opacity: 1; /* Show button on hover */
          }
  
          .card_body {
            border-radius: 0 0 12px 12px; /* Rounded bottom corners */
            color: #fff;
            
            h3 {
              font-size: 14px; /* Card title size */
              font-weight: 600;
              margin-bottom: 5px;
              color: black; /* Title color */
            }
  
            p {
              font-size: 12px; /* Description font size */
              color: $pry-color; /* Description color */
              margin-top: 0; /* No margin */
            }
          }
        }
      }
    }
  }
}




