// Mixin.scss
// @mixin flex-center($direction: row, $justify: center, $align: center) {
//     display: flex;
//     flex-direction: $direction;
//     justify-content: $justify;
//     align-items: $align;
// }

@mixin responsive($property, $value) {
    @media (max-width: $breakpoint-md) {
        #{$property}: $value;
    }
}

@mixin flex-center($direction: row, $justify: center, $align: center, $breakpoint: null) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
  
    @if $breakpoint {
      @media (max-width: $breakpoint) {
        flex-direction: column; // Example: stack elements on smaller screens
        justify-content: flex-start; // Change alignment if necessary
        align-items: stretch; // Change alignment if necessary
      }
    }
  }


// Functions.scss

@function rem($value) {
    @return #{$value / 16}rem; // Convert pixels to rem
}

@function em($value, $context: 16) {
    @return #{$value / $context}em; // Convert pixels to em based on context
}

@function breakpoint($size) {
    @if $size ==md {
        @return 768px; // Medium breakpoint
    }

    @else if $size ==lg {
        @return 1024px; // Large breakpoint
    }

    @else {
        @return null; // Default case
    }
}


// Functions.scss
@function responsive-font-size($size, $min: 0.8rem, $max: 3rem) {
    @return #{max($min, min($max, #{$size * 1vw}))}; // Clamp between min and max
  }


  @function responsive-size($base-size, $min: 0, $max: null) {
    $calculated-size: #{$base-size * 1vw}; // Convert base size to vw

    @if $max {
        @return max($min, min($max, $calculated-size)); // Clamp between min and max
    } @else {
        @return max($min, $calculated-size); // Only clamp min
    }
}


// Mixin.scss

@mixin responsive-image($max-width: 100%, $tablet-width: 90%, $mobile-width: 100%) {
    width: $max-width; // Set the width to the max-width
    height: auto; // Maintain aspect ratio

    @media (max-width: 768px) {
        max-width: $tablet-width; // Adjust for tablet screens
    }

    @media (max-width: 480px) {
        max-width: $mobile-width; // Adjust for mobile screens
    }
}


  

// Functions.scss

@function responsive-spacing($default, $tablet: null, $mobile: null) {
    $spacing: '';

    // Add default spacing
    $spacing: 'margin: #{$default};';

    // Add tablet spacing if provided
    @if $tablet {
        $spacing: $spacing + '@media (max-width: 768px) { margin: #{$tablet}; }';
    }

    // Add mobile spacing if provided
    @if $mobile {
        $spacing: $spacing + '@media (max-width: 480px) { margin: #{$mobile}; }';
    }

    @return $spacing;
}


