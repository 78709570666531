@import "../Configration/Veriabales.scss";
@import "../Configration/Mixin.scss";

.department_banner {
    background:url("../../images/dt_bg.webp");
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    padding: 8rem 0;
    background-size: cover;
   
  }
  .wrp {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 8px;
    // padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    margin-top: -15%;  // Use margin to shift up instead of top
    margin-bottom: 5rem;
    h3{
      font-size: responsive-font-size(1.5);
      padding-top: 1rem;
    }
    ul{
      padding-left: 3rem;
      padding-top: 1rem;
      li{
      }
    }
    
  }
  
  .leftSection {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .doctorImage {
    width: 100%;
    height: 400px;
    // border-radius: 50%; // Circular image
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .rightSection {
    flex: 2;
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h5{
      font-size: reponsive-font-size(1.1);
      color: $white-color;
      line-height: 28px;
    }
    h5:nth-child(4){
      margin-bottom: 5rem;
    }
  }
  
  .doctorName {
    font-size: 48px;
    color: $white-color; // Dark blue like in the image
    font-weight: 600;
    margin: 0 0 10px 0;
  }
  
  .speciality {
    font-size: 24px;
    color: #ebf5fa; // Light blue
    font-weight: 400;
    margin: 0 0 20px 0;
    margin-bottom: 5rem;
  }
  
  .description {
    font-size: 16px;
    color: #333; // Darker gray for text
    line-height: 1.6;
    margin-bottom: 10px;
    white-space: pre-line;
  }
  
  .bookButton {
    background-color: #007bff; // Bright blue button
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s;
    width: fit-content; 
    margin-top: 2rem;
  }
  
  .bookButton:hover {
    background-color: #0056b3;
  }
    
.modalContainer {
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  box-shadow: 24;
  padding: 30px;
  border-radius: 10px;

  h4{
    font-size: 20px;
    font-weight: 500;
  }
}

.modalTitle {
  color: #000000;
  font-size: 21px;
  font-weight: 600;
}

.modalSubtitle {
  font-weight: 400;
  font-size: 14px;
  color: #918E8E;
  padding-top: 10px;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 2rem 0;
}

.inputField {
  border-radius: 10px;
}

.weekContainer {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.dayLink {
  cursor: pointer;
  background-color: #D1EEFF69;
  padding: 0.5rem 1rem;
  border-radius: 50px;
  margin-bottom: 10px;
  text-align: center; // Optional for visual indication
  transition: background-color 0.3s;
  border: 1px solid $sec-color;
  color: $sec-color;
  &:hover{
    background-color: $pry-color; // Change this to your desired color
    color:$white-color; 
  }
}

.selectedDay {
  background-color: $pry-color; // Change this to your desired color
  color:$white-color; // Change text color if needed
}

.timeSlots {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  column-gap: 2rem;
  // justify-content: center;
}

.timeSlot {
  background-color: #D1EEFF69;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  text-align: center;
}

.selectedSlot {
  border: 2px solid $pry-color; // Highlight selected slot
}

.bookButton {
  margin-top: 20px;
  display: inline-block;
  padding: .75rem 2rem;
  border: none;
  color: #fff;
  text-transform: uppercase;
  font-size: responsive-font-size(1);
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $pry-color;

    z-index: -2;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: darken($pry-color, 15%);
    transition: all .3s;

    z-index: -1;
  }

  &:hover {
    color: #fff;

    &:before {
      width: 100%;
    }
  }
}

@media screen and (max-width:1200px) {

.department_banner {
    background:url("../../images/dt_bg.webp");
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    padding: 8rem 0;
    background-size: cover;
   
  }
  .wrp {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 8px;
    // padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    margin-top: -20%;  // Use margin to shift up instead of top
    margin-bottom: 5rem;
  }
  
  .leftSection {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .doctorImage {
    width: 100%;
    height: 100%;
    // border-radius: 50%; // Circular image
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .rightSection {
    flex: 2;
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .doctorName {
    font-size: 36px;
    color: #ffffff; // Dark blue like in the image
    font-weight: 600;
    margin: 0 0 10px 0;
  }
  
  .speciality {
    font-size: 16px;
    color: #ebf5fa; // Light blue
    font-weight: 400;
    margin: 0 0 20px 0;
    margin-bottom: 5rem;
  }
 
  
  .description {
    font-size: 16px;
    color: #333; // Darker gray for text
    line-height: 1.6;
    margin-bottom: 10px;
    white-space: pre-line;
  }
  
  .bookButton {
    background-color: #007bff; // Bright blue button
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s;
    width: fit-content; 
    margin-top: 2rem;
  }
  
  .bookButton:hover {
    background-color: #0056b3;
  }
    
.modalContainer {
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  box-shadow: 24;
  padding: 30px;
  border-radius: 10px;

  h4{
    font-size: 20px;
    font-weight: 500;
  }
}

.modalTitle {
  color: #000000;
  font-size: 21px;
  font-weight: 600;
}

.modalSubtitle {
  font-weight: 400;
  font-size: 14px;
  color: #918E8E;
  padding-top: 10px;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 2rem 0;
}

.inputField {
  border-radius: 10px;
}

.weekContainer {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.dayLink {
  cursor: pointer;
  background-color: #D1EEFF69;
  padding: 0.5rem 1rem;
  border-radius: 50px;
  margin-bottom: 10px;
  text-align: center; // Optional for visual indication
  transition: background-color 0.3s;
  border: 1px solid $sec-color;
  color: $sec-color;
  &:hover{
    background-color: $pry-color; // Change this to your desired color
    color:$white-color; 
  }
}

.selectedDay {
  background-color: $pry-color; // Change this to your desired color
  color:$white-color; // Change text color if needed
}

.timeSlots {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  column-gap: 2rem;
  // justify-content: center;
}

.timeSlot {
  background-color: #D1EEFF69;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  text-align: center;
}

.selectedSlot {
  border: 2px solid $pry-color; // Highlight selected slot
}

.bookButton {
  margin-top: 20px;
  display: inline-block;
  padding: .75rem 2rem;
  border: none;
  color: #fff;
  text-transform: uppercase;
  font-size: responsive-font-size(1);
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $pry-color;

    z-index: -2;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: darken($pry-color, 15%);
    transition: all .3s;

    z-index: -1;
  }

  &:hover {
    color: #fff;

    &:before {
      width: 100%;
    }
  }
}
}

@media screen and (max-width:993px) {

  .department_banner {
      background:url("../../images/dt_bg.webp");
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      padding: 8rem 0;
      background-size: cover;
     
    }
    .wrp {
      display: grid;
      grid-template-columns: 1fr 2fr;
      align-items: flex-start;
      justify-content: space-between;
      border-radius: 8px;
      // padding: 20px;
      max-width: 1200px;
      margin: 0 auto;
      position: relative;
      margin-top: -25%;  // Use margin to shift up instead of top
      margin-bottom: 5rem;
    }
    
    .leftSection {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .doctorImage {
      width: 100%;
      height: 100%;
      // border-radius: 50%; // Circular image
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }
    
    .rightSection {
      flex: 2;
      padding-left: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h5{
        font-size: 16px;
        color: $white-color;
        line-height: 28px;
      }
      h5:nth-child(4){
        margin-bottom: 5rem;
      }
      p{
        font-size: 12px;
      }

    }
    
    .doctorName {
      font-size: 28px;
      color: #ffffff; // Dark blue like in the image
      font-weight: 600;
      margin: 0 0 10px 0;
    }
    
    .bookButton {
      background-color: #007bff; // Bright blue button
      color: white;
      padding: 12px 24px;
      border: none;
      border-radius: 5px;
      font-size: 18px;
      font-weight: 500;
      cursor: pointer;
      transition: background-color 0.3s;
      width: fit-content; 
      margin-top: 2rem;
    }
    
    .bookButton:hover {
      background-color: #0056b3;
    }
      
  .modalContainer {
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    box-shadow: 24;
    padding: 30px;
    border-radius: 10px;
  
    h4{
      font-size: 20px;
      font-weight: 500;
    }
  }
  
  .modalTitle {
    color: #000000;
    font-size: 21px;
    font-weight: 600;
  }
  
  .modalSubtitle {
    font-weight: 400;
    font-size: 14px;
    color: #918E8E;
    padding-top: 10px;
  }
  
  .inputContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin: 2rem 0;
  }
  
  .inputField {
    border-radius: 10px;
  }
  
  .weekContainer {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }
  
  .dayLink {
    cursor: pointer;
    background-color: #D1EEFF69;
    padding: 0.5rem 1rem;
    border-radius: 50px;
    margin-bottom: 10px;
    text-align: center; // Optional for visual indication
    transition: background-color 0.3s;
    border: 1px solid $sec-color;
    color: $sec-color;
    &:hover{
      background-color: $pry-color; // Change this to your desired color
      color:$white-color; 
    }
  }
  
  .selectedDay {
    background-color: $pry-color; // Change this to your desired color
    color:$white-color; // Change text color if needed
  }
  
  .timeSlots {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    column-gap: 2rem;
    // justify-content: center;
  }
  
  .timeSlot {
    background-color: #D1EEFF69;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    text-align: center;
  }
  
  .selectedSlot {
    border: 2px solid $pry-color; // Highlight selected slot
  }
  
  .bookButton {
    margin-top: 20px;
    display: inline-block;
    padding: .75rem 2rem;
    border: none;
    color: #fff;
    text-transform: uppercase;
    font-size: responsive-font-size(1);
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
  
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $pry-color;
  
      z-index: -2;
    }
  
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: darken($pry-color, 15%);
      transition: all .3s;
  
      z-index: -1;
    }
  
    &:hover {
      color: #fff;
  
      &:before {
        width: 100%;
      }
    }
  }
  }

  @media screen and (max-width:767px) {

    .department_banner {
        background:url("../../images/dt_bg.webp");
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        padding: 8rem 0;
        background-size: cover;
       
      }
      .wrp {
        display: grid;
        grid-template-columns: 1fr 2fr;
        align-items: flex-start;
        justify-content: space-between;
        border-radius: 8px;
        // padding: 20px;
        max-width: 1200px;
        margin: 0 auto;
        position: relative;
        margin-top: -25%;  // Use margin to shift up instead of top
        margin-bottom: 5rem;
      }
      
      .leftSection {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .doctorImage {
        width: 100%;
        height: 100%;
        // border-radius: 50%; // Circular image
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      }
      
      .rightSection {
        flex: 2;
        padding-left: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h5{
          font-size: 14px;
          color: $white-color;
          line-height: 18px;
        }
        h5:nth-child(4){
          margin-bottom: 3rem;
        }
        p{
          font-size: 12px;
        }
  
      }
      
      .doctorName {
        font-size: 22px;
        color: #ffffff; // Dark blue like in the image
        font-weight: 600;
        margin: 0 0 10px 0;
      }
      
      .bookButton {
        background-color: #007bff; // Bright blue button
        color: white;
        padding: 12px 24px;
        border: none;
        border-radius: 5px;
        font-size: 18px;
        font-weight: 500;
        cursor: pointer;
        transition: background-color 0.3s;
        width: fit-content; 
        margin-top: 2rem;
      }
      
      .bookButton:hover {
        background-color: #0056b3;
      }
        
    .modalContainer {
      background-color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 60%;
      box-shadow: 24;
      padding: 30px;
      border-radius: 10px;
    
      h4{
        font-size: 20px;
        font-weight: 500;
      }
    }
    
    .modalTitle {
      color: #000000;
      font-size: 21px;
      font-weight: 600;
    }
    
    .modalSubtitle {
      font-weight: 400;
      font-size: 14px;
      color: #918E8E;
      padding-top: 10px;
    }
    
    .inputContainer {
      display: flex;
      flex-direction: row;
      gap: 20px;
      margin: 2rem 0;
    }
    
    .inputField {
      border-radius: 10px;
    }
    
    .weekContainer {
      display: flex;
      justify-content: space-between;
      margin: 20px 0;
    }
    
    .dayLink {
      cursor: pointer;
      background-color: #D1EEFF69;
      padding: 0.5rem 1rem;
      border-radius: 50px;
      margin-bottom: 10px;
      text-align: center; // Optional for visual indication
      transition: background-color 0.3s;
      border: 1px solid $sec-color;
      color: $sec-color;
      &:hover{
        background-color: $pry-color; // Change this to your desired color
        color:$white-color; 
      }
    }
    
    .selectedDay {
      background-color: $pry-color; // Change this to your desired color
      color:$white-color; // Change text color if needed
    }
    
    .timeSlots {
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      column-gap: 2rem;
      // justify-content: center;
    }
    
    .timeSlot {
      background-color: #D1EEFF69;
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 10px;
      cursor: pointer;
      text-align: center;
    }
    
    .selectedSlot {
      border: 2px solid $pry-color; // Highlight selected slot
    }
    
    .bookButton {
      margin-top: 20px;
      display: inline-block;
      padding: .75rem 2rem;
      border: none;
      color: #fff;
      text-transform: uppercase;
      font-size: responsive-font-size(1);
      transition: all .3s;
      position: relative;
      overflow: hidden;
      z-index: 1;
    
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $pry-color;
    
        z-index: -2;
      }
    
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: darken($pry-color, 15%);
        transition: all .3s;
    
        z-index: -1;
      }
    
      &:hover {
        color: #fff;
    
        &:before {
          width: 100%;
        }
      }
    }
    }
  
  @media screen and  (max-width: 567px) {
    .department_banner {
      background:url("../../images/dt_bg.webp");
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      padding: 8rem 0;
      background-size: cover;
     
    }
    .wrp {
      display: grid;
      grid-template-columns: 1fr ;
      align-items: flex-start;
      justify-content: space-between;
      border-radius: 8px;
      // padding: 20px;
      max-width: 1200px;
      margin: 0 auto;
      position: relative;
      margin-top: -40%;  // Use margin to shift up instead of top
      margin-bottom: 1rem;
    }
    
    .leftSection {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .doctorImage {
      width: 100%;
      height: 400px;
      // border-radius: 50%; // Circular image
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      object-fit: cover;
    }
    
    .rightSection {
      flex: 2;
      padding-left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 1rem;
      h5{
        font-size: 12px;
        color:#000;
        line-height: 18px;
      }
      h5:nth-child(4){
        margin-bottom: 2rem;
      }
      p{
        font-size: 11px;
      }
    }
    
    .doctorName {
      font-size: 18px;
      color: $pry-color; // Dark blue like in the image
      font-weight: 600;
      margin: 0 0 10px 0;
    }
    
    .speciality {
      font-size: 16px;
      color: #0b0b0b; // Light blue
      font-weight: 400;
      margin: 0 0 20px 0;
      margin-bottom: 1rem;
    }
    
    .description {
      font-size: 12px;
      color: #333; // Darker gray for text
      line-height: 1.6;
      margin-bottom: 10px;
    }
    
    .bookButton {
      background-color: #007bff; // Bright blue button
      color: white;
      padding: 8px 15px;
      border: none;
      border-radius: 5px;
      font-size: 12px;
      font-weight: 500;
      cursor: pointer;
      transition: background-color 0.3s;
      width: fit-content; 
      margin-top: 1rem;
    }
    
    .bookButton:hover {
      background-color: #0056b3;
    }

    .modal-container {
      background-color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      justify-content: center;
      box-shadow: 24px 24px 24px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      padding: 20px;
      border-radius: 10px;
      align-items: center;
    }
    .inputContainer {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin: 2rem 0;
    }
    .timeSlots {
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      column-gap: 2rem;
      gap: 1rem;
      flex-wrap: wrap;
      // justify-content: center;
    }
    
    .modal-image {
      max-width: 80%;
      height: 80%;
      padding: 10px;
    }
    
    .modal-title {
      color: #5b5b5b;
      font-size: 20px;
      font-weight: 600;
      padding: 10px;
    }
    
    .modal-text {
      font-weight: 400;
      font-size: 17px;
      color: #3a3a3a;
      padding: 10px;
      text-align: center;
    }

    .modalContainer {
      background-color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      box-shadow: 24;
      padding: 30px;
      border-radius: 10px;
      overflow-y: scroll;
    
      h4{
        font-size: 20px;
        font-weight: 500;
      }
    }
    .weekContainer {
      display: flex;
      justify-content: flex-start;
      margin: 20px 0;
      flex-wrap: wrap;
      column-gap: 1rem;
    }
    
  }
  // Ensure responsiveness
  @media screen and  (max-width: 467px) {
    .department_banner {
      background:url("../../images/dt_bg.webp");
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      padding: 8rem 0;
      background-size: cover;
     
    }
    .wrp {
      display: grid;
      grid-template-columns: 1fr ;
      align-items: flex-start;
      justify-content: space-between;
      border-radius: 8px;
      // padding: 20px;
      max-width: 1200px;
      margin: 0 auto;
      position: relative;
      margin-top: -40%;  // Use margin to shift up instead of top
      margin-bottom: 1rem;
    }
    
    .leftSection {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .doctorImage {
      width: 100%;
      height: 400px;
      // border-radius: 50%; // Circular image
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      object-fit: cover;
    }
    
    .rightSection {
      flex: 2;
      padding-left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 1rem;
      h5{
        font-size: 12px;
        color:#000;
        line-height: 18px;
      }
      h5:nth-child(4){
        margin-bottom: 2rem;
      }
      p{
        font-size: 11px;
      }
    }
    
    .doctorName {
      font-size: 18px;
      color: $pry-color; // Dark blue like in the image
      font-weight: 600;
      margin: 0 0 10px 0;
    }
    
    .speciality {
      font-size: 16px;
      color: #0b0b0b; // Light blue
      font-weight: 400;
      margin: 0 0 20px 0;
      margin-bottom: 1rem;
    }
    
    .description {
      font-size: 12px;
      color: #333; // Darker gray for text
      line-height: 1.6;
      margin-bottom: 10px;
    }
    


    .modal-container {
      background-color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      justify-content: center;
      box-shadow: 24px 24px 24px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      padding: 20px;
      border-radius: 10px;
      align-items: center;
    }
    .inputContainer {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin: 2rem 0;
    }
    .timeSlots {
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      column-gap: 2rem;
      gap: 1rem;
      flex-wrap: wrap;
      // justify-content: center;
    }
    
    .modal-image {
      max-width: 80%;
      height: 80%;
      padding: 10px;
    }
    
    .modal-title {
      color: #5b5b5b;
      font-size: 20px;
      font-weight: 600;
      padding: 10px;
    }
    
    .modal-text {
      font-weight: 400;
      font-size: 17px;
      color: #3a3a3a;
      padding: 10px;
      text-align: center;
    }

    .modalContainer {
      background-color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      box-shadow: 24;
      padding: 30px;
      border-radius: 10px;
      overflow-y: scroll;
    
      h4{
        font-size: 20px;
        font-weight: 500;
      }
    }
    .weekContainer {
      display: flex;
      justify-content: flex-start;
      margin: 20px 0;
      flex-wrap: wrap;
      column-gap: 1rem;
    }
    
  }




  